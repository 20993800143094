export class ActionsEvent {


    async viewHistory(parent: any, data) {
        parent._router.navigate([`/p/projects/paymenthistory`], {queryParams: {project: data.id}})
    }


    canShowComponent(parent) {


        if (parent.formDetail.canShow) {
            const value = parent.getModelValue(parent.formDetail.canShow.modelChecker)
            if (parent.formDetail.canShow.value === false) {
                if (!value) parent.ref.nativeElement.style.display = 'block'
                else parent.ref.nativeElement.style.display = 'none'
            }


            if (parent.formDetail.canShow.value === true) {
                if (value) parent.ref.nativeElement.style.display = 'block'
                else parent.ref.nativeElement.style.display = 'none'
            }
        }

    }

}
