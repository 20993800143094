import {Component, ElementRef, OnInit} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {CrudService} from '../../../../services/crud-service'
import {NotifDialogService} from '../../../../services/notif-service'
import {ButtonSubmitModel} from './button-submit-model'
import {UserService} from '../../../../services/user/user-service'
import {TimeoutWait} from '../../../../helpers/helper-async-timeout'

@Component({
    selector: 'vold-button-submit',
    templateUrl: './button-submit.component.html',
    styleUrls: ['./button-submit.component.scss'],

})

export class ButtonSubmitComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()

    buttonSubmitDetail: ButtonSubmitModel

    constructor(public ref: ElementRef,
                private _notifDialog: NotifDialogService,
                private _crudService: CrudService,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {


        this.buttonSubmitDetail = this.formDetail.buttonSubmit

    }


    async saveUpdate() {
        this.busy = true

        const buttonDetail = this.formDetail.buttonSubmit
        const response = buttonDetail.response
        const responseData = buttonDetail.responseData
        let updateId = this.model.id
        let form = this.model
        let saved: any


        /**
         * In case if data is an Array
         */
        if (Array.isArray(this.model)) {
            updateId = this.model[0].id
            form = this.model[0]
        }


        if (buttonDetail.type === 'POST') {

            await TimeoutWait(200)

            if (buttonDetail.form) {

                let newForm: any = {}

                Object.keys(buttonDetail.form).forEach((key) => {
                    newForm[key] = this.model[buttonDetail.form[key]]
                })

                saved = await this._crudService.POST(buttonDetail.model, newForm)
            }

        } else {
            saved = await this._crudService[buttonDetail.type || 'POST'](buttonDetail.model || this.formDetail.pathModel, updateId, form)
        }


        if (saved) {


            if (response && response.message) {
                if (response.message === saved.message) {
                    if (response.thenUpdateModel) {
                        this.setModelValue(response.changValueTo, response.thenUpdateModel)
                        this.onDataChange.emit(this.model)
                    }
                }
            }


            if (responseData) {
                this.onDataChange.emit(saved[responseData])
                this.busy = false
                this._notifDialog.showDialog({
                    type: 'success',
                    message: this.renderResponseMessage(buttonDetail.responseMessage, saved)

                })
                return
            }

            this._notifDialog.showDialog({
                type: 'success',
                message: `${buttonDetail.responseMessage ? buttonDetail.responseMessage : `Successfully update post ${saved.id}`}`

            })

            this.onDataChange.emit(this.model)
        }

        this.busy = false
    }


    renderResponseMessage(message: string, data: any) {
        let responseMessage: string = `Successfully update post ${data.id}`

        if (message && message.indexOf('|') > -1) {
            const str = message.split('|')
            responseMessage = `${str[0]} ${this.getModelValueFromData(str[1], data)}`
        } else {
            responseMessage = message
        }

        return responseMessage

    }

}
