import {CardLayoutSchema} from '../../../models/dynamic-compopent/card-model'

export const CARD_CLIENT_JCO_ROLE: CardLayoutSchema = {
    apiModel: {
        path: 'role',
        params: '?sort=created_at DESC',
        limit: 20,
        createNewFields: [

            /**
             * name
             * url
             * status
             * is_active
             */
            {
                title: 'Name',
                model: 'title',
                required: true,
                component: 'InputComponent',
                blockSize: 12
            },
        ]
    },

    editor: {
        prefixPath: 'p/manage/manage-role/editor'
    },


    model: {
        id: 'id',
        cardTable: {
            searchInModel: 'name',
            header: [
                'Name',
                'Created At'
            ],
            filterByHeader: [
                'name',
                'created_at'
            ],
            data: [
                'name',
                'created_at,%d'
            ],
            colSize: [6, 6],
        }
    }
}
