import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs/Observable'
import {Injectable} from '@angular/core'
import {UserService} from '../services/user/user-service'
import {CrudService} from '../services/crud-service'
import {WebsiteSettingsService} from '../services/settings/website-settings.service'

@Injectable()
export class DashboardResolver implements Resolve<any> {

    constructor(private _userService: UserService,
                private _websiteSettings: WebsiteSettingsService,
                private _crudService: CrudService) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return Observable.create(async dashboard => {
            this._userService.clientDashboardConfig = await this._crudService.SINGLE('client', this._userService.getClientId())

            /**
             * @for Edy
             * @todo Please fix the messed
             * @type {ArrayBuffer}
             */
            let dashboardConfig: any = await this._crudService.GET('webconfig')
            dashboardConfig = dashboardConfig.results[0]
            
            this._userService.websiteSettings = dashboardConfig
            this._websiteSettings.config = dashboardConfig
            if (this._userService.storageService.get('language')) this._userService.language = this._userService.storageService.get('language')

            if (dashboardConfig.language) {
                this._userService.websiteLanguages = dashboardConfig.language
            }

            dashboard.next(dashboardConfig)
            dashboard.complete()
        })

    }
}
