import { CARD_PRODUCT_PRODOTTI } from './card-product-prodotti';
import {CARD_ACCOMMODATION} from './card-accommodation'
import {CARD_BRANDS} from './card-brands'
import {CARD_CAREERS} from './card-carrers'
import {CARD_NEWS} from './card-news'
import {CARD_OUR_STORY} from './card-our-story'
import {CARD_OUR_STORY_BOGA} from './card-our-story-boga'
import {CARD_CLIENT_JCO_USER} from './vip/card-client-jco-user'
import {CARD_CLIENT_JCO_REGION} from './vip/jco/card-client-jco-region'
import {CARD_CLIENT_JCO_ROLE} from './vip/jco/card-client-jco-role'
import {CARD_AFFILIATES_BIMASENA} from './card-affiliates-bimasena'
import {CARD_BUNGALOWS} from './card-bungalows-tanjungsari'
import {CARD_STORES} from './card-stores'
import {CARD_PRODUCT_CATEGORY} from './card-product-category'
import {CARD_PRODUCT} from './card-product'
import {CARD_JCO_CAREERS} from './card-jco-career'
import {CARD_EVENT} from './card-event'
import {CARD_LEARN} from './card-learn'
import {CARD_JCO_NEWS} from './card-jco-news'
import {CARD_JCO_FLASH} from './card-jco-flash'
import {CARD_JCO_PDF_MENU} from './card-jco-pdf-menu'
import {CARD_CATEGORY} from './card-category'
import {CARD_OFFERS_TANJUNGSARI} from './card-offers-tanjungsari'

export const TABLE_SETTINGS = {
    CARD_ACCOMMODATION,
    CARD_BRANDS,
    CARD_CAREERS,
    CARD_NEWS,
    CARD_OUR_STORY,
    CARD_OUR_STORY_BOGA,
    CARD_CLIENT_JCO_USER,
    CARD_CLIENT_JCO_REGION,
    CARD_CLIENT_JCO_ROLE,
    CARD_AFFILIATES_BIMASENA,
    CARD_BUNGALOWS,
    CARD_STORES,
    CARD_PRODUCT_CATEGORY,
    CARD_PRODUCT,
    CARD_JCO_CAREERS,
    CARD_EVENT,
    CARD_LEARN,
    CARD_JCO_NEWS,
    CARD_JCO_FLASH,
    CARD_JCO_PDF_MENU,
    CARD_CATEGORY,
    CARD_OFFERS_TANJUNGSARI,
    CARD_PRODUCT_PRODOTTI


}
