import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'
import {TextDisplayModel} from './text-display-model'
import {environment} from '../../../../../environments/environment'

@Component({
    selector: 'vold-text-display',
    templateUrl: './text-display.component.html',
    styleUrls: ['./text-display.component.scss'],

})

export class TextDisplayComponent extends CreatorExtendCore implements OnInit {

    textDisplay: TextDisplayModel = new TextDisplayModel()
    assetUrl: string

    constructor(public ref: ElementRef,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {

        this.textDisplay = this.formDetail.textDisplay

        if (this.textDisplay && this.textDisplay.type === 'asset')
            this.assetUrl = environment.assets_uri

    }
}
