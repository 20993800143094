import {Pipe, PipeTransform} from '@angular/core'
import {environment} from '../../environments/environment'
import {UserService} from '../services/user/user-service'

@Pipe({
    name: 'imageSource'
})
export class ImageSourcePipe implements PipeTransform {

    constructor(private _userService: UserService) {
    }

    transform(image: any, size: string, isFullPath?: boolean) {

        if (!image)
            if (this._userService.websiteSettings && this._userService.websiteSettings.default_image) {
                image = `${environment.assets_uri}${this._userService.websiteSettings.default_image.path}`
            } else {
                image = './assets/images/mock/no-image.jpg'
            }

        else {
            if (size) {
                image = `${environment.assets_uri}${(image.thumbnails ? image.thumbnails[size] : image.path) || image}`
            } else {

                if (typeof image === 'object') {
                    if (image.path && image.path.indexOf('http') > -1 || image.full_path && image.full_path.indexOf('http') > 0) {
                        image = `${image.path}` || `${image.full_path}`
                    } else {
                        image = `${environment.assets_uri}${image.path}` || `${environment.assets_uri}${image.full_path}`
                    }

                } else {
                    image = `${environment.assets_uri}${image}`
                }

            }
        }

        return image
    }

}
