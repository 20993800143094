import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

@Component({
    selector: 'vold-social-media-editor',
    templateUrl: './social-media-editor.component.html',
    styleUrls: ['./social-media-editor.component.scss']
})

export class SocialMediaEditorComponent implements OnInit {

    @ViewChild('updateButton') updateButton: ElementRef
    @ViewChild('buttonClose') buttonClose: ElementRef

    edit: boolean = false
    editingIcon: any = {}
    socialMedia: any[] = []
    editingIconIndex: number = -1
    busy: boolean = false

    listSocialMedia: any[] = [
        {type: 'trip-advisor'},
        {type: 'facebook'},
        {type: 'instagram'},
        {type: 'google-plus'},
        {type: 'telegram'},
        {type: 'whatsapp'},
        {type: 'twitter'},
        {type: 'youtube'},
        {type: 'pinterest'}
    ]

    formSetting: FormModelSchema[] = [
        {
            title: 'Title',
            model: 'title',
            component: 'InputComponent',
        },
        {
            title: 'Link',
            model: 'link',
            required: true,
            component: 'InputComponent',
        }
    ]


    constructor() {
    }

    ngOnInit() {


        this.listSocialMedia.forEach(icon => {
            this.socialMedia.forEach(selected => {
                if (icon.type === selected.type) {
                    icon.selected = true
                }
            })
        })
    }

    deleteIcon(icon) {

        if (this.editingIconIndex > -1) {
            this.socialMedia.splice(this.editingIconIndex, 1)

            this.listSocialMedia.map(icn => {
                if (icn.type === icon.type) icn.selected = false
            })

            this.edit = false
            this.editingIconIndex = -1
        }
    }

    selectIcon(icon) {
        icon.selected = true
        this.socialMedia.push({type: icon.type})
    }

    viewDetail(icon, index) {
        this.editingIcon = icon
        this.edit = true
        this.editingIconIndex = index

    }

}
