import {Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild} from '@angular/core'
import {FileManagerService} from '../../services/file-manager/file-manager.service'
import {CARD_FILE_MANAGER} from '../../cards/card-file-manager'
import {InfiniteScrollService} from '../../services/infinite-scroll/infinite-scroll.service'
import {CrudService} from '../../services/crud-service'
import {ModalPopupService} from '../modal/service/modal.service'
import {VirtualScrollComponent} from 'angular2-virtual-scroll'
import {Subject} from 'rxjs/Rx'
import {FileUploadService} from '../../services/file-manager/file-upload.service'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Component({
    selector: 'vold-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

    @ViewChild('outerScrollContainer') outerScrollContainer: ElementRef
    @ViewChild('folderContainer') folderContainer: ElementRef
    @ViewChild('fileListings') fileListings: ElementRef
    @ViewChild(VirtualScrollComponent) private _virtualScroll: VirtualScrollComponent
    @ViewChild('buttonClose') buttonClose: ElementRef

    $selectedFileListener: Subject<any> = new Subject<any>()

    isFileSelected: string

    accessFromButton: boolean
    multipleSelection: boolean


    /**
     * Single selection
     */
    singleSelection: any

    /**
     * Store data for multiple selection
     * @type {any[]}
     */
    multiSelectionFiles: any[] = []

    files: any

    subFolder: any = []

    navigation: any = []

    listings: any = []

    cardSchema = CARD_FILE_MANAGER

    isLoaded: boolean

    showBreadCrumb: boolean

    parentSelected: any

    subSelected: any

    viewPortItems: any
    uploadInProgress: boolean = false
    createNewFolder: boolean = false

    constructor(private _fileManager: FileManagerService,
                private _infiniteScroll: InfiniteScrollService,
                private _factoryResolver: ComponentFactoryResolver,
                private _modalService: ModalPopupService,
                private _el: ElementRef,
                private _fileUpload: FileUploadService,
                private _crudService: CrudService) {
    }

    ngOnInit() {
        this.createNavigation()
        if (this.accessFromButton) this._el.nativeElement.classList.add('from-button')
    }


    async createNavigation() {
        this.files = await this._fileManager.getFolder()
    }

    onDeleteImage(data) {

        let model = this.parentSelected.folder_name.toLowerCase()
        if (model === 'image') model = 'picture'
        this._crudService.DELETE(`${model}`, data.id)
        this.subSelected.files.filter((item, index) => {
            if (item.id === data.id) {
                this.subSelected.files.splice(index, 1)
            }
        })

        /**
         * Because of non immutable array
         * we need delete parent array
         */
        this.parentSelected.files.filter((item, index) => {
            if (item.id === data.id) {
                this.parentSelected.files.splice(index, 1)
            }
        })

        this.subSelected.total = this.subSelected.total - 1
        this._virtualScroll.refresh(true)
    }

    async pushFiles(newFiles: any) {

        let action = 'unshift'
        if (this.subSelected.files.length === 0) action = 'push'

        this.viewPortItems[action](...newFiles.files)
        this.subSelected.files[action](...newFiles.files)
        this.parentSelected.files[action](...newFiles.files)
        await TimeoutWait(200)
        this._virtualScroll.refresh(true)
    }

    async folderSelected(folder: any) {

        this.listings = []
        this.subFolder = []
        const test: any = {}
        folder.files.map(sub => {
            if (!test[sub.type]) test[sub.type] = []
            test[sub.type].push(sub)
        })

        for (let key in test) {
            if (test.hasOwnProperty(key)) {
                this.subFolder.push({
                    folder_name: key,
                    files: test[key],
                    total: test[key].length,
                    is_sub: true
                })
            }
        }

        this.subSelected = null
        this.parentSelected = folder
        this.isLoaded = false
    }

    openSubFolder(folder: any) {
        this.subSelected = folder
        this.isLoaded = true
    }

    selectRoot() {
        this.subSelected = null
        this.parentSelected = null
        this.isLoaded = false
    }

    toTitle(val: string) {
        if (!val) return
        return val.replace(/_/g, ' ')
    }


    selectFile(file: any) {

        if (this.accessFromButton) {

            if (!this.multipleSelection) {

                if (file.is_selected) return
                this.isFileSelected = file.id

                this.singleSelection = file
                this.subSelected.files.filter((item) => {
                    item.is_selected = item.id === this.isFileSelected
                })

                /**
                 * Because of non immutable array
                 * we need delete parent array
                 */
                this.parentSelected.files.filter((item) => {
                    item.is_selected = item.id === this.isFileSelected
                })

            } else {

                const f = this.multiSelectionFiles.findIndex(d => d.id === file.id)

                if (f > -1) {
                    file.is_selected = false
                    this.multiSelectionFiles.splice(f, 1)
                    return
                }

                file.is_selected = true
                this.multiSelectionFiles.push(file)
            }
        }
    }

    onApplySelected(cb?) {
        if (cb) cb()
    }

    applySelected() {

        if (!this.multipleSelection) this.$selectedFileListener.next(this.singleSelection)
        else this.$selectedFileListener.next(this.multiSelectionFiles)
        this.onApplySelected()
        if (this.accessFromButton) this._modalService.closeModal()
    }

    getSelectedFile() {
        return this.$selectedFileListener.asObservable()
    }


    closeFileManager() {
        this._modalService.closeModal()
    }

    async onFileChange(files: any) {

        this.uploadInProgress = true

        /**
         * Checking file
         * @type {any}
         */
        const isSecure = await this._fileUpload.imageRestricted(files, this.parentSelected.folder_name.toLowerCase())

        if (!isSecure) return this.uploadInProgress = false

        const image: any = await this._fileUpload.uploadFile({
            type: this.subSelected.folder_name || 'global',
            file: files
        }, this.parentSelected.folder_name)

        this.pushFiles(image)
        this.uploadInProgress = false


    }

    createFolder(folder) {

        this.subFolder.push({
            folder_name: folder.folderName,
            files: folder.files,
            total: folder.files.length,
            is_sub: true
        })

        this.createNewFolder = false

        // this._modalService.injectToBody(CreateNewFolderComponent, (injector, comp) => {
        //     comp.instance.getUploadedData().subscribe(folder => {
        //         this.subFolder.push({
        //             folder_name: folder.folderName,
        //             files: folder.files,
        //             total: folder.files.length,
        //             is_sub: true
        //         })
        //         injector.destroy()
        //     })
        // })

    }
}
