import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    templateUrl: './card-simple.component.html',
    styleUrls: ['./card-simple.component.scss'],
})
export class CardSimpleComponent extends CreatorExtendCore implements OnInit {


    constructor(public ref: ElementRef, public userService: UserService) {
        super(userService, ref)
    }

}
