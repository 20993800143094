import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'
import {CrudService} from '../crud-service'
import {CreatePostTypeModel} from '../../models/post-type/create-post-type-model'
import {UserService} from '../user/user-service'

@Injectable()
export class CreateNewPostService {

    showPostSetting: boolean = false
    postSettingsForm = [
        {
            title: 'Parent Page',
            component: 'SelectOptionComponent',
            model: 'parent_page',
            options: {
                remote: true,
                apiModel: {
                    path: 'page',
                    params: 'sort="title ASC"'
                },
                value: 'title:lang',
            },
            userRole: ['superadmin', 'admin'],
            blockSize: 12
        },

        {
            title: 'Post Type',
            component: 'InputComponent',
            type: 'hidden',
            model: 'post_type',
            blockSize: 6
        },
    ]


    private _$listenNewPost: Subject<any> = new Subject<any>()

    constructor(private _crudService: CrudService,
                private _userService: UserService) {

    }

    $setNewPost(val: any) {
        this._$listenNewPost.next(val)
    }

    $getNewPost() {
        return this._$listenNewPost.asObservable()
    }


    async createPostType(model: string, form: CreatePostTypeModel) {
        return await this._crudService.POST(model, form)
    }


    getPostTypeSettings(postType?: string) {
        const webConfig: any = this._userService.websiteSettings
        if (!webConfig.posts_setting) this._userService.websiteSettings['posts_setting'] = []
        const getPostTypeSetting: any[] = webConfig.posts_setting
        if (getPostTypeSetting.length === 0) {
            return {
                post_type: postType,
                parent_page: null,
                is_create_new: true
            }
        } else {
            let postSetting = getPostTypeSetting.find(post => post.post_type === postType)

            if (!postSetting) {
                postSetting = {
                    post_type: postType,
                    parent_page: null,
                    is_create_new: true
                }
            }

            return postSetting
        }
    }


    async updatePostSettingBasePage(newData) {

        if (newData.is_create_new) {
            this._userService.websiteSettings.posts_setting.push({
                post_type: newData.post_type,
                parent_page: newData.parent_page
            })
            await this._crudService.PUT('webconfig', this._userService.websiteSettings.id, this._userService.websiteSettings)
        } else {
            await this._crudService.PUT('webconfig', this._userService.websiteSettings.id, this._userService.websiteSettings)
        }

        console.log('this._userService.websiteSettings.posts_setting', newData)


        /**
         * Update page selected
         */
        await this._crudService.PUT('page', newData.parent_page, {
            post_type: newData.post_type
        })

    }
}
