import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Rx'
import {SubjectsService} from '../subjects.service'

@Injectable()
export class DomActionService {

    private _$listenEscHint: Subject<any> = new Subject<any>()

    constructor(private _subService: SubjectsService) {

    }

    $setEscHint(val: boolean) {
        this._subService.$setSubject('esc_hint', val)
    }

    $listenEscHint(res: any) {
        this._subService.$subscribeSubject('esc_hint', res)
    }


}
