import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core'
import {CrudService} from '../services/crud-service'
import {CreateCardComponentService} from '../services/create/create-card-component.service'
import {HttpParams} from '@angular/common/http'
import {InfiniteScrollService} from '../services/infinite-scroll/infinite-scroll.service'

@Directive({
    selector: '[voldInfiniteScroll]'
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {

    @Output('onDataUpdate') onDataUpdate: EventEmitter<any> = new EventEmitter<any>()
    @Output('onLoading') onLoading: EventEmitter<boolean> = new EventEmitter<boolean>()

    private _currentLimit: number

    private _canScroll: boolean = true

    private _endOfList: boolean = false

    private _sub: any = []

    constructor(private _el: ElementRef,
                private _infiniteScrollService: InfiniteScrollService,
                private _createCard: CreateCardComponentService,
                private _crudService: CrudService) {

    }


    ngAfterViewInit() {


        /**
         * Listen if card Detail is changed
         */
        this._sub.push(
            this._createCard.$getCardDetail().subscribe(() => {
                this._canScroll = true
            }),

            /**
             * Every time data has changed reset the scroll position
             */
            this._infiniteScrollService.$listenHostUpdateData().subscribe(() => {

                this._endOfList = false

                /**
                 * Bring scroll into top position
                 * @type {number}
                 */
                this._el.nativeElement.scrollTop = 0

                /**
                 * Reset number fo skipper data
                 * @type {number | undefined}
                 * @private
                 */
                this._currentLimit = this._createCard.cardDetail.apiModel.limit || 20
                this._canScroll = true
            })
        )

        this._currentLimit = this._createCard.cardDetail.apiModel.limit || 20

        this._el.nativeElement.addEventListener('scroll', async (event) => {


            if (!this._canScroll) return

            if (event.target.scrollTop === (event.target.scrollHeight - event.target.offsetHeight)) {

                this._canScroll = false

                this.onLoading.emit(true)

                /**
                 * Just stay calm
                 */
                if (this._endOfList) return this.onLoading.emit(false)

                const data: any = await this._fetchingData()

                this._endOfList = data.length === 0

                // this._currentLimit = this._currentLimit + this._createCard.cardDetail.apiModel.limit
                this._currentLimit = this._currentLimit + +this._createCard.modelDefaultParams.limit

                this._canScroll = true

                this.onDataUpdate.emit(data)

                this.onLoading.emit(false)
            }
        })

    }

    private async _fetchingData() {


        const params = this._createCard.activeParams

        /**
         * Add data skipper
         * @type {number}
         */
        params['skip'] = this._currentLimit

        return await this._crudService.GET(`${this._createCard.cardDetail.apiModel.path}`, {
            params: new HttpParams({fromObject: params})
        })

    }


    ngOnDestroy() {
        this._sub.forEach(sub => {
            sub.unsubscribe()
        })
    }
}
