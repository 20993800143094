import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'
import {environment} from '../../environments/environment'
import {UserService} from '../services/user/user-service'

@Pipe({
    name: 'backgroundImgSafe'
})
export class BackgroundImgSafePipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer,
                private _userService: UserService) {
    }

    transform(image: any, size: string) {

        let featured = image

        if (image && typeof image === 'object') {

            if (size && size !== 'local') {
                if (image.thumbnails) {
                    featured = image.thumbnails[size] || image.path
                } else {
                    featured = image.path
                }

            }

            else if (image.thumb) {
                featured = image[size] || image['thumb']
            }

            else {
                featured = image.path
            }
        }


        let img = `${environment.assets_uri}${(featured ? featured.replace('/assets/', '/') : featured)}`

        if (featured && featured.indexOf('http') > -1)
            img = `${(featured ? featured.replace('/assets/', '/') : featured)}`


        if (!featured) {
            if (this._userService.websiteSettings && this._userService.websiteSettings.default_image) {
                img = `${environment.assets_uri}${this._userService.websiteSettings.default_image.path}` || './assets/images/mock/no-image.jpg'
            } else {
                img = './assets/images/mock/no-image.jpg'
            }
        }


        if (size === 'local') {
            img = image
        }

        return this._sanitizer.bypassSecurityTrustStyle(`url("${img}")`)
    }

}
