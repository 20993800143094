import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'

@Pipe({name: 'toFixed'})

export class ToFixed implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(str) {

        let num = str.split('.')
        if (num.pop().length > 1) {
            let int = Number(str)
            return int.toFixed(2)
        }
        else {

            return str
        }





    }
}
