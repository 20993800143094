import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core'
import {CrudService} from '../../../services/crud-service'
import {CardLayoutSchema} from '../../../models/dynamic-compopent/card-model'
import {ActivatedRoute, Router} from '@angular/router'
import {ModalPopupService} from '../../../cores/modal/service/modal.service'
import {EditorService} from '../../../services/editor/editor-service'
import {CreatorExtendCore} from '../../../cores/creators/extend/card-extend-core'
import {
    CardTableModel,
    TableGroupModel,
    TableItemLegendSchema
} from '../../../cores/creators/card-component/card-table/card-table-model'
import {UserService} from '../../../services/user/user-service'
import {NotifDialogService} from '../../../services/notif-service'
import {CreateCardComponentService} from '../../../services/create/create-card-component.service'
import {ElasticDataService} from '../../../services/elastic/elastic-data-service'
import {LOOPING} from '../../../helpers/helper-looping'
import {InfiniteScrollService} from '../../../services/infinite-scroll/infinite-scroll.service'
import {HttpParams} from '@angular/common/http'
import {VirtualScrollComponent} from 'angular2-virtual-scroll'
import {EditorDataService} from '../../../services/editor/editor-data-service'

@Component({
    selector: 'vold-layout-table-card-view',
    templateUrl: './layout-card-table-view.component.html',
    styleUrls: ['./layout-card-table-view.component.scss']
})
export class LayoutCardTableViewComponent extends CreatorExtendCore implements OnInit {

    viewPortItems: any

    cardData: any = []

    vData: any = []

    isLoaded: boolean

    isLoadingData: boolean

    cardTableDetail: CardLayoutSchema

    cardTableSchema: CardTableModel

    routeDetail: any

    tableGroup: TableGroupModel[] = []

    activeGroup: any = null

    busySorting: boolean

    showOptionAtIndex: number = -1

    queryParams: any

    showLegendDetail: boolean

    query: string

    currSortActive: string
    currSortType: string

    @ViewChildren('itemElement') itemElement: QueryList<ElementRef>

    @ViewChild(VirtualScrollComponent) private _virtualScroll: VirtualScrollComponent


    constructor(private _crudService: CrudService,
                private _router: Router,
                private _el: ElementRef,
                private _viewRef: ViewContainerRef,
                private _notifDialog: NotifDialogService,
                private _modalService: ModalPopupService,
                private _activatedRoute: ActivatedRoute,
                private _createData: CreateCardComponentService,
                private _elastic: ElasticDataService,
                private _editorService: EditorService,
                private _editorDataService: EditorDataService,
                private _infiniteScrollService: InfiniteScrollService,
                public userService: UserService) {

        super(userService)
    }

    async ngOnInit() {


        this.isLoadingData = true

        this.sub.push(
            this._activatedRoute.queryParams.subscribe(params => {

                if (Object.keys(params).length > 0) {
                    this.queryParams = params
                }
            }),

            this._activatedRoute.data.subscribe(async (res: any) => {

                if (res.cardDetail.formSettings) this._editorDataService.formSettings = res.cardDetail.formSettings

                /**
                 * Reset active params from previous page
                 * @type {{}}
                 */
                this._createData.activeParams = {}

                if (!res.cardDetail) return this.isLoaded = true

                /**
                 * Needed for infinite scroll
                 * @type {CardModelSchema | CardLayoutSchema | any}
                 * @private
                 */
                this._createData.cardDetail = res.cardDetail

                this.routeDetail = res
                this.cardTableDetail = res.cardDetail
                this._editorService.currentEditedPage = res.title

                this.cardTableSchema = this.cardTableDetail.model.cardTable

                /**
                 * Make sure array given not a strings
                 */
                if (!Array.isArray(this.cardTableSchema.header)) {
                    this.cardTableSchema.header = this.cardTableSchema.header.split(',')
                    this.cardTableSchema.header.push('Created At')
                }
                if (!Array.isArray(this.cardTableSchema.data)) {
                    this.cardTableSchema.data = this.cardTableSchema.data.split(',')
                        .map(item => item.replace(/ /g, ''))
                    this.cardTableSchema.data.push('created_at,%d')
                }
                if (!Array.isArray(this.cardTableSchema.filterByHeader)) {
                    this.cardTableSchema.filterByHeader = this.cardTableSchema.filterByHeader.split(',')
                        .map(item => item.replace(/ /g, ''))
                    this.cardTableSchema.filterByHeader.push('created_at')
                }


                if (!this.cardTableSchema.colSize) {
                    this.cardTableSchema['colSize'] = []
                    this.cardTableSchema.header.map(header => {
                        this.cardTableSchema.colSize.push(this.cardTableSchema.header.length / 4)
                    })
                }


                /**
                 * Getting data
                 * @type {any}
                 */
                const cardData: any = await this._fetchingData(this.cardTableDetail.apiModel)

                this._renderData(cardData.results)


                /**
                 * Has group create navigation
                 */
                if (this.cardTableSchema.group) {
                    this.activeGroup = this.cardTableSchema.group[0]

                    /**
                     * Set editor for group form
                     */
                    this.cardTableDetail.editor = this.activeGroup.editor
                    this.tableGroup.push(...this.cardTableSchema.group)
                }


                this.isLoaded = true
                this.isLoadingData = false
            })
        )

    }

    searchQuery() {
        if (!this.query.length) {
            this.cardData = [].concat(this.vData)
        } else {
            const data = this.vData.filter(d => d[this.cardTableSchema.searchInModel].toLowerCase().indexOf(this.query.toLowerCase()) > -1)
            this.cardData = [].concat(data)
        }
    }


    /**
     * Resetting results from url query
     */
    async resetResults() {
        /**
         * Remove query params from url
         * @type {string}
         */
        let url: string = this._router.url.substring(0, this._router.url.indexOf('?'))
        if (url) this._router.navigate([url])

        /**
         * Reseting data
         * @type {boolean}
         */
        this.busySorting = true
        this.queryParams = null
        /**
         * Reset value
         * @type {{}}
         */
        this._createData.activeParams = {}
        const newData = await this._fetchingData(this.cardTableDetail.apiModel)
        this.replaceData(newData)
        this._infiniteScrollService.$hostDataUpdated(true)
        this.busySorting = false
    }

    private async _fetchingData(model, params: any = {}) {

        let setParams = Object.assign({}, this._createData.modelDefaultParams, this._createData.activeParams, params)

        if (typeof(model.params) === 'object') setParams = Object.assign({}, setParams, model.params)

        if (this.queryParams) setParams = Object.assign({}, setParams, this.queryParams)

        /**
         * Always set active params
         * @type {*}
         */
        this._createData.activeParams = setParams

        return this._crudService.GET(`${model.path}`, {
            params: new HttpParams({fromObject: setParams})
        })

    }

    async elasticItemClicked(data) {
        /**
         * Set detail data
         * This will help
         * @type {null}
         */

        if (this.cardTableDetail.elastic.reloadData) {

            let params = {}

            if (this.cardTableDetail.elastic.extendModelQuery) {
                this.queryParams = {}
                this.queryParams[this.cardTableDetail.elastic.extendModelQuery] = data.id
                params[this.cardTableDetail.elastic.extendModelQuery] = data.id
            }
            delete this._createData.activeParams['skip']
            const newData = await this._fetchingData(this.cardTableDetail.apiModel, params)
            this.replaceData(newData)
            this._infiniteScrollService.$hostDataUpdated(true)
            return
        }

        this.viewDetail(data)
    }


    viewDetail(data) {
        this._editorService.data.singleData = null
        this._editorService.data.cardDetail = this.cardDetail
        this._editorService.currentEditedPage = this.routeDetail.title

        if (this._editorDataService.getFormSettings()) {
            const routeEditor = `${this._activatedRoute.parent.snapshot.routeConfig.path}/${this._activatedRoute.snapshot.routeConfig.path}/editor/${data.id}`
            this._router.navigate([`p/${routeEditor}`])
        } else this._router.navigate([`${this.cardTableDetail.editor.prefixPath}/${data.id}`])
    }

    async deleteItem(item, index) {

        let title = item.name

        if (item.title && item.title[this.language]) title = item.title[this.language]

        this._notifDialog.showDialog({
            type: 'confirm',
            message: `Are you sure want to delete <b>${title || 'Untitled'}</b>?`,
            confirmFn: async (confirm) => {

                if (confirm) {
                    const deleteItem: any = await this._crudService.DELETE(this.cardTableDetail.apiModel.deleteModel || this.cardTableDetail.apiModel.path, item.id)

                    if (deleteItem) {

                        this.cardData.map((d, i) => {
                            if (item.id === d.id) {
                                this.cardData.splice(i, 1)
                                this.vData.splice(i, 1)
                                this.viewPortItems.splice(index, 1)
                            }
                        })

                        // this.cardData.splice(index, 1)

                        // this.cardData
                    }
                }
            }
        })

    }


    loadingData(loading, loadingItem) {

        if (loading) {
            loadingItem.classList.add('active')
        } else {
            setTimeout(() => {
                loadingItem.classList.remove('active')
            }, 200)
        }

    }


    pushData(data) {

        /**
         * Push data
         */
        if (data.results.length > 0) this._renderData(data.results)

    }

    replaceData(data) {
        // this.cardData = data
        this._renderData(data, false)
        this._infiniteScrollService.$hostDataUpdated(true)
    }


    async sortBy(model?: any) {
        /**
         * Gusna taen mai
         */
        if (!model) return

        this.busySorting = true

        let sortBy = 'DESC'
        let sortParams: any = {}

        if (model === this.currSortActive) {
            if (this.currSortType === 'DESC') sortBy = 'ASC'
            else sortBy = 'DESC'
        }

        /**
         * Always reset the skipper number
         */
        delete this._createData.activeParams['skip']

        sortParams['sort'] = `${model} ${sortBy}`

        const newSortedData: any = await this._fetchingData(this.cardTableDetail.apiModel, sortParams)

        this._renderData(newSortedData.results, false)

        /**
         * Tell infinite scroll if data has change
         */
        this._infiniteScrollService.$hostDataUpdated(true)

        this.currSortActive = model
        this.currSortType = sortBy

        this.busySorting = false

        // if (!model) return
        //
        // this.busySorting = true
        //
        // let sortBy = 'DESC'
        // let sortParams: any = {}
        //
        // if (model === this.currSortActive) {
        //     if (this.currSortType === 'DESC') sortBy = 'ASC'
        //     else sortBy = 'DESC'
        // }
        //
        // /**
        //  * Always reset the skipper number
        //  */
        // delete this._createData.activeParams['skip']
        //
        // sortParams['sort'] = `${model} ${sortBy}`
        //
        // const newSortedData: any = await this._fetchingData(this.cardTableDetail.apiModel, sortParams)
        // this._renderData(newSortedData.results, false)
        //
        // /**
        //  * Tell infinite scroll if data has change
        //  */
        // this._infiniteScrollService.$hostDataUpdated(true)
        //
        // this.currSortActive = model
        // this.currSortType = sortBy
        //
        // this.busySorting = false

    }

    private _renderData(cardData: any, pushData: boolean = true) {

        this.cardData = []

        cardData.forEach((data: any) => {

            data['rendered_data'] = []

            LOOPING(this.cardTableSchema.data, model => {

                let ngModel = model
                if (model.indexOf(':lang') > -1) {
                    ngModel = model.replace(':lang', '')
                }

                data.rendered_data.push(this.getModelValueFromData(ngModel, data))

            })

            LOOPING(this.cardTableSchema.legends, (legend: TableItemLegendSchema) => {

                const value = this.getModelValueFromData(legend.model, data)
                if (value === legend.modelValue) {
                    data['legend_color'] = legend.color
                }

            })

            /**
             * This is only for user if their login with facebook
             */
            if (data.isFacebookLogin) data.featured = data.fb_picture.data.url


        })

        if (pushData) this.cardData.push(...cardData)
        else this.cardData.push(...cardData)


        /**
         * Because of virtual scroll need an immutable data
         * we need save the resource to use in search and filter
         * @type {any}
         */
        this.vData = cardData

        this._virtualScroll.refresh(true)
    }


    showOptionNavigation(index) {
        if (this.showOptionAtIndex === index) this.showOptionAtIndex = -1
        else this.showOptionAtIndex = index
    }

    doAction(action, item) {


        if (action) this[action](this, item)

    }


}
