import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {PipesModule} from '../../../../pipes/pipes.module'
import {SingleImageComponent} from './single-image.component'
import {DirectivesBundleModule} from '../../../../directives/directives-bundle.module'
import {FormsModule} from '@angular/forms'
import {ImageEditorModule} from './image-editor/image-editor.module'

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        DirectivesBundleModule,
        FormsModule,
        ImageEditorModule
    ],

    exports: [
        SingleImageComponent
    ],

    declarations: [
        SingleImageComponent
    ],
    entryComponents: [
        SingleImageComponent
    ]
})
export class SingleImageModule {
}
