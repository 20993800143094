import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {LOOPING, LOOPING_ASYNC} from '../../../../helpers/helper-looping'
import {FormModelSchema} from '../../../../models/dynamic-compopent/form-model'
import {TimeoutWait} from '../../../../helpers/helper-async-timeout'
import {UserService} from '../../../../services/user/user-service'
import {NotifDialogService} from '../../../../services/notif-service'

@Component({
    selector: 'vold-multiple-add',
    templateUrl: './multiple-add.component.html',
    styleUrls: ['./multiple-add.component.scss'],
})

export class MultipleAddComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    tempArray: any[] = []
    multipleAddDetail: any = {}

    tmpModel: any = []

    showEditorInput: boolean

    showEditorForm: number = -1
    @Input('formChooser') formChooser: any

    @ViewChildren('groupInputHolder', {read: ViewContainerRef}) groupInputHolder: QueryList<ViewContainerRef>
    @ViewChildren('formEditor') formEditor: QueryList<ElementRef>
    @ViewChild('inputEditor', {read: ViewContainerRef}) inputEditor: ViewContainerRef

    constructor(private _ref: ElementRef,
                public userService: UserService,
                private _notifDialog: NotifDialogService,
                private _componentResolver: ComponentFactoryResolver) {
        super(userService)
    }

    async extendOnInit() {

        this.multipleAddDetail = {
            options: this.formDetail.multipleAdd,
            components: this.formDetail.multipleComponentToUse
        }

        await TimeoutWait(300)

        await this.createData()

    }


    async createNewInput() {

        this.showEditorInput = true

        await this.createData(true)

        const index = this.tempArray.length - 1

        await TimeoutWait(200)

        this.createComponent(index)

    }

    async closeEditor() {

        this.tempArray = []

        this.showEditorInput = false

        await TimeoutWait(50)

        await this.createData()

    }

    async createData(createNew: boolean = false) {

        if (createNew) this.tempArray.push(this.dynamicMultipleComp)
        else this.tempArray.push(...this.getModelValue())

        LOOPING_ASYNC(this.tempArray, (data, index) => {
            this.tmpModel[index] = []

            LOOPING(this.multipleAddDetail.components, comp => {
                this.tmpModel[index].push(comp.model.replace(':lang', ''))
            })

        })
    }


    createComponent(index?: number) {


        const multi: any = this.multipleAddDetail.components
        this.inputEditor.clear()


        console.log('Multiple add', multi)

        LOOPING(multi, (input: any) => {

            /**
             * Create new model
             * prevent input model override with new model
             * @type {FormModelSchema}
             */
            const model = new FormModelSchema()

            for (let key in input) {
                model[key] = input[key]
            }


            console.log('input', input)

            model.model = `${this.formDetail.model}.${index}.${input.model}`


            console.log('model.model', model.model)

            const resolver = this._componentResolver.resolveComponentFactory(this.formChooser[input.component])
            const newComponent: any = this.inputEditor.createComponent(resolver)

            newComponent.instance.formDetail = model
            newComponent.instance.model = this.model
        })

    }

    editItem(index) {
        this.showEditorInput = true
        this.showEditorForm = index
        this.createComponent(index)
    }

    removeItem(index: number, buttonName?: any) {


        this._notifDialog.showDialog({
            type: 'confirm',
            message: `Are you sure want to delete?`,
            confirmFn: async (confirm) => {


                if (confirm) {
                    this.tempArray.splice(index, 1)
                    /**
                     * Yup remove also array inside of model
                     */
                    this.getModelValue().splice(index, 1)
                    this.onDataChange.emit(this.model)
                }
            }
        })

    }
}
