import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {CalendarComponent} from './calendar.component'
import {DirectivesBundleModule} from '../../directives/directives-bundle.module'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DirectivesBundleModule
    ],

    exports: [
        CalendarComponent
    ],

    declarations: [
        CalendarComponent
    ],

    entryComponents: [
        CalendarComponent
    ]
})
export class CalendarModule {
}
