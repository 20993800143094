import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core'
import {LOOPING} from '../helpers/helper-looping'
import {TimeoutWait} from '../helpers/helper-async-timeout'

@Directive({
    selector: '[voldSlider]'
})
export class SliderDirective implements OnInit, AfterViewInit {

    private _currentIndexActive: number = 0

    constructor(private _el: ElementRef) {
    }

    async ngOnInit() {
        // this._el.nativeElement.classList.add('vold-slides')
    }


    async ngAfterViewInit() {
        await TimeoutWait(1000)
        this._createWrapper()
    }

    private _createWrapper() {
        const container = this._el.nativeElement
        const children = container.children
        const childrenCount = children.length

        const cloning = container.cloneNode(true)

        cloning.className = ''
        cloning.classList.add('vold-slider-wrapper')

        /**
         * Remove children html
         * @type {string}
         */
        container.innerHTML = ''

        container.appendChild(cloning)
        container.classList.add('vold-slides')

        /**
         * Append new children
         */
        // container.appendChild(wrapper)
        //
        this._createStyle(cloning, childrenCount)
        this._createNavigation(cloning, childrenCount)

    }


    private _createStyle(slidesWrapper, childrenCount) {
        const container = this._el.nativeElement
        const children = slidesWrapper.children
        const containerWidth = container.innerWidth || container.offsetWidth


        LOOPING(children, (elem, index) => {
            elem.style.width = `${containerWidth}px`
            elem.style.position = `absolute`
            elem.style.left = `${containerWidth * index}px`
        })

        slidesWrapper.style.position = 'absolute'
        slidesWrapper.style.width = `${containerWidth * childrenCount}px`
        slidesWrapper.style.height = `${container.innerHeight || container.offsetHeight}px`
    }

    private _createNavigation(slidesWrapper, childrenCount) {
        const container = this._el.nativeElement
        const children = slidesWrapper.children
        const containerWidth = container.innerWidth || container.offsetWidth
        const navigationContainer = document.createElement('div')
        const prev = document.createElement('div')
        const next = document.createElement('div')

        navigationContainer.classList.add('vold-slides-navigation')

        prev.classList.add('prev-nav', 'navigation', 'icon-arrow-next', 'in-active')
        next.classList.add('next-nav', 'navigation', 'icon-arrow-next')


        prev.addEventListener('click', (event) => {
            if (this._currentIndexActive === 0) return
            this._currentIndexActive -= 1
            next.classList.remove('in-active')
            slidesWrapper.style.transform = `translate3d(-${containerWidth * this._currentIndexActive}px, 0 , 0)`

            if (this._currentIndexActive === 0)
                prev.classList.add('in-active')
        })


        next.addEventListener('click', (event) => {

            if (this._currentIndexActive >= (childrenCount - 1)) return
            this._currentIndexActive += 1
            prev.classList.remove('in-active')
            slidesWrapper.style.transform = `translate3d(-${containerWidth * this._currentIndexActive}px, 0 , 0)`

            if (this._currentIndexActive === (childrenCount - 1))
                next.classList.add('in-active')
        })

        navigationContainer.appendChild(prev)
        navigationContainer.appendChild(next)


        /**
         * Yes for multiple image only
         */
        if (children.length > 1)
            container.appendChild(navigationContainer)

        children[this._currentIndexActive].classList.add('selected')
    }

}
