import {CardLayoutSchema} from '../models/dynamic-compopent/card-model'

export const CARD_NEWS: CardLayoutSchema = {
    apiModel: {
        path: 'news',
        params: '?sort=created_at DESC',
        limit: 20,
        createNewFields: [
            {
                title: 'Title',
                model: 'title:lang',
                required: true,
                component: 'InputComponent',
            },
            {
                model: 'status',
                type: 'hidden',
                value: 'draft',
                component: 'InputComponent',
            }
        ]
    },

    editor: {
        prefixPath: 'p/manage/news/editor'
    },


    model: {
        id: 'id',
        cardTable: {
            header: [
                'Report',
                'Created At'
            ],
            filterByHeader: [
                'title',
                'created_at'
            ],
            data: [
                'title:lang',
                'created_at,%d'
            ],
            colSize: [6, 6],
        }
    }
}
