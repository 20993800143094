import {ElementRef, Injectable} from '@angular/core'
import {Subject} from 'rxjs/Rx'
import {environment} from '../../environments/environment'
import {SALT_KEY} from '../cores/builder/constants/pb-constant'
import {SubjectsService} from './subjects.service'

@Injectable()
export class IframeDataService {

    constructor(private _subjectService: SubjectsService) {

    }

    private _$listenPostMessage: Subject<any> = new Subject<any>()
    private _$listenData: Subject<any>[] = []


    /**
     * Store all action key from client
     * @param {string} key
     * @param message
     */
    iframeClientActionKey: any


    listenMessage(key: string, message: any = null) {
        this._$listenPostMessage.next({
            key,
            message
        })
    }


    sendPostMessage(iframe: ElementRef, message) {
        iframe.nativeElement.contentWindow.postMessage(message, environment.receiver_origin)
    }


    getPostMessage() {
        return this._$listenPostMessage.asObservable()
    }


    $setListener(key: string, value: any) {
        this._$listenData[key].next(value)
    }


    $listenSubject(key: string) {
        if (!this._$listenData[key]) this._$listenData[key] = new Subject<any>()
        return this._$listenData[key].asObservable()
    }

    sendMessageToParent(key, message) {
        window.parent.postMessage({
            key: key,
            message: message,
            salt: SALT_KEY
        }, environment.access_origin)
    }


}
