import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'
import {FileUploadService} from '../../../../services/file-manager/file-upload.service'
import {environment} from '../../../../../environments/environment'
import {MediumEditorDirective} from '../../../../directives/medium-editor.directive'
import {EditorDataService} from '../../../../services/editor/editor-data-service'
import {TextEditorModel} from './text-editor-model'
import {SafePipeUrl} from '../../../../pipes/safe-pipe'

@Component({
    selector: 'vold-text-medium-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    providers: [SafePipeUrl]

})

export class TextEditorComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    imageInjector: any = null
    uploadingImage: boolean
    textEditor: TextEditorModel
    embedVideoUrl: string
    showVidUrlEditor: boolean
    showMediaButton: boolean
    @Input('editorToolButton') editorToolButton: string[] = []
    @ViewChild('mediumEditor') mediumEditor: MediumEditorDirective

    constructor(public ref: ElementRef,
                private _fileUploadService: FileUploadService,
                private _editorService: EditorDataService,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {
        this.textEditor = this.formDetail.textEditor
        if (this.textEditor && this.textEditor.buttons) this.mediumEditor.toolBar = this.textEditor.buttons

        console.log('this.editorToolButton', this.mediumEditor.toolBar)
    }

    async uploadMedia(files) {

        this.uploadingImage = true

        const image = await this._fileUploadService.uploadFile({
            file: files,
            type: 'editor',
        }, 'image')


        if (image.files.length > 0) this.imageInjector.innerHTML = `<img width="100%" height="auto" src="${environment.assets_uri}${image.files[0].path}">`


        this._editorService.$setImageInserted()

        this.imageInjector = null

        this.uploadingImage = false


    }

    canInsertImage(event) {
        this.imageInjector = event

    }

    embedVideo() {

        if (this.embedVideoUrl) {
            this.showVidUrlEditor = false
            this.imageInjector.innerHTML = `<iframe src="https://www.youtube.com/embed/${this.embedVideoUrl}?rel=0&showinfo=0" frameborder="0" width="100%" height="100%"></iframe>`
            this._editorService.$setImageInserted()
            this.imageInjector = null
            this.uploadingImage = false
            this.embedVideoUrl = null
        } else {
            this.showVidUrlEditor = true
        }

    }


}
