import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {GoogleMapComponent} from './google-map.component'
import {PipesModule} from '../../../../pipes/pipes.module'

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],

    exports: [
        GoogleMapComponent
    ],

    declarations: [
        GoogleMapComponent
    ],

    entryComponents: [
        GoogleMapComponent
    ]
})

export class GoogleMapModule {
}
