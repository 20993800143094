import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core'
import * as interact from 'interactjs'
import {DragDropService} from '../services/drag-drop/drag-drop.service'

@Directive({
    selector: '[dragDrop]'
})

export class DragDropDirective implements AfterViewInit {

    @Output('onDropItem') onDrop: EventEmitter<any> = new EventEmitter<any>()
    @Output('onDragStart') onDragStart: EventEmitter<any> = new EventEmitter<any>()

    private _activatedSort: boolean

    constructor(private _el: ElementRef,
                private _dragDropService: DragDropService) {
    }

    ngAfterViewInit() {
        this._el.nativeElement.setAttribute('draggable', true)
        this._el.nativeElement.classList.add('draggable')


        this._dragDropService.$getActivatedSort().subscribe(res => {
            this._activatedSort = res
        })


        this._dragAbleAction()
    }


    private _dragAbleAction() {


        interact(this._el.nativeElement)
            .draggable({
                // enable inertial throwing
                inertia: true,
                // keep the element within the area of it's parent
                restrict: {
                    restriction: (this._activatedSort ? 'parent' : '#navigation-accepted-zone'),
                },

                onstart: (event) => {
                    console.log('start')
                    event.target.style.zIndex = 99999
                    this.onDragStart.emit(event)
                },

                // call this function on every dragmove event
                onmove: (event) => {
                    this.dragMoveListener(event)
                },
                // call this function on every dragend event
                onend: (event) => {
                    event.target.style.webkitTransform = event.target.style.transform = 'translate(0, 0)'
                    event.target.setAttribute('data-x', 0)
                    event.target.setAttribute('data-y', 0)

                    if (this._activatedSort) event.target.classList.remove('drag-zone-sort')
                },
            })
            .dropzone({
                overlap: 0.75,

                ondragenter: (event) => {

                    if (this._activatedSort)
                        event.target.classList.add('drag-zone-sort')
                    else
                        event.target.classList.add('drag-zone')
                },

                ondragleave: (event) => {
                    event.target.classList.remove('drag-zone')

                    if (this._activatedSort) event.target.classList.remove('drag-zone-sort')
                },

                ondrop: (event) => {
                    this.onDrop.emit(event)
                    event.relatedTarget.style.webkitTransform = event.relatedTarget.style.transform = 'translate(0, 0)'
                    event.target.classList.remove('drag-zone')
                    event.target.setAttribute('data-x', 0)
                    event.target.setAttribute('data-y', 0)

                    // console.log('event', event)
                },

                ondropdeactivate: (event) => {
                    // remove active dropzone feedback
                    event.target.classList.remove('drop-active')
                    event.target.classList.remove('drop-zone')
                }
            })
    }


    dragMoveListener(event) {
        let target = event.target,
            //     // keep the dragged position in the data-x/data-y attributes
            x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
            y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

        target.classList.add('dragged-element')


        if (this._activatedSort)
            x = 0

        target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
        //
        // // update the posiion attributes
        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)

        // this.onDrop.emit({x, y})
    }

}
