import {Directive, HostBinding, HostListener, OnDestroy} from '@angular/core'

@Directive({
    selector: '[voldSimpleDropdown]'
})

export class SimpleDropdownDirective implements OnDestroy {

    private timer = null
    private isMouseInside: boolean = false
    @HostBinding('class.open') isOpen: boolean = false

    @HostListener('click')
    toggleOpen() {
        this.isOpen = !this.isOpen
    }

    @HostListener('document:click')
    onDocumentClick() {
        if (!this.isMouseInside)
            this.isOpen = false
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.isMouseInside = false
        this.scheduleTimeout()
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.isMouseInside = true
        this.cancelTimeout()
    }

    scheduleTimeout() {
        this.cancelTimeout()

        this.timer = setTimeout(() => {
            this.timer = null
            this.isOpen = false
        }, 500)
    }

    cancelTimeout() {
        let t = this.timer
        this.timer = null

        if (t !== null)
            clearTimeout(t)
    }


    ngOnDestroy() {
        this.cancelTimeout()
    }
}
