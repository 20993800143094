import {enableProdMode} from '@angular/core'
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic'

import {AppModule} from './app/app.module'
import {environment} from './environments/environment'

if (environment.production) {
    enableProdMode()
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

bootstrap().then(() => {
    if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/dashboard/ngsw-worker.js')
    }
}).catch(err => console.log(err))
