import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'
import {CheckBoxComponentModel} from './check-box-component.model'
import {CrudService} from '../../../../services/crud-service'

@Component({
    selector: 'vold-check-box-component',
    templateUrl: './check-box-component.component.html',
    styleUrls: ['./check-box-component.component.scss']
})
export class CheckBoxComponentComponent extends CreatorExtendCore implements OnInit {
    checkBoxConfig: CheckBoxComponentModel
    checkBoxOptions: any = []
    selectedOptions: any = []

    constructor(public ref: ElementRef,
                public userService: UserService,
                private _crudService: CrudService) {
        super(userService, ref)
    }

    extendOnInit() {
        const savedData = this.getModelValue()
        if (savedData) this.selectedOptions.push(...savedData)
        this.checkBoxConfig = this.formDetail.checkBox
        if (this.checkBoxConfig && this.checkBoxConfig.remote) this.getOnlineData()
        else this.getLocal()
    }

    async getOnlineData() {


        let params = {}
        if (this.checkBoxConfig.apiModel.params) params = this.checkBoxConfig.apiModel.params
        const data: any = await this._crudService.GET(this.checkBoxConfig.apiModel.path, {
            params: params
        })
        data.results.map(d => {
            d.label = this.getModelValueFromData(this.checkBoxConfig.label, d)

            if (this.checkBoxConfig.valueCheck) {
                const checker = this.getModelValueFromData(this.checkBoxConfig.valueCheck, d)
                if (checker === this.selectedOptions.filter(dt => dt === checker)[0]) d.is_active = true
            } else {
                const value = d[this.checkBoxConfig.setValueModal]
                if (value === this.selectedOptions.filter(dt => dt === value)[0]) d.is_active = true
            }
        })
        this.checkBoxOptions.push(...data.results)

    }

    getLocal() {

        if (!this.checkBoxConfig) return

        if (!this.checkBoxConfig.options) {

            const modelData = this.getModelValue()
            this.setModelLabel(modelData)
            this.checkBoxOptions.push(...modelData)

        } else {

            if (!this.checkBoxConfig.options) return
            this.setModelLabel(this.checkBoxConfig.options)
            // .map((d: any) => {
            //     d.label = this.getModelValueFromData(this.checkBoxConfig.label, d)
            //     const value = d[this.checkBoxConfig.setValueModal]
            //     if (value === this.selectedOptions.filter(dt => dt === value)[0]) d.is_active = true
            // })
            this.checkBoxOptions.push(...this.checkBoxConfig.options)
        }
    }


    setModelLabel(arr: any[]) {
        return arr.map((d: any) => {
            d.label = this.getModelValueFromData(this.checkBoxConfig.label, d)
            const value = d[this.checkBoxConfig.setValueModal]

            if (this.checkBoxConfig.checkAll) {
                d.is_active = true
            } else {
                if (value === this.selectedOptions.filter(dt => dt === value)[0]) d.is_active = true
            }
        })
    }

    selectItem(item: any, index?: number) {

        const value = this.getModelValueFromData(this.checkBoxConfig.setValueModal, item)
        const indexNumber = this.selectedOptions.findIndex(d => d === value)
        if (indexNumber >= 0) {
            this.selectedOptions.splice(indexNumber, 1)
            item.is_active = false
            this.setModelValue(this.selectedOptions)
            return
        }

        item.is_active = true
        this.selectedOptions.push(value)
        this.setModelValue(this.selectedOptions)

        if (this.checkBoxConfig.callBackOnClick) this.checkBoxConfig.callBackOnClick(item, index, this)

    }


}
