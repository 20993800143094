import {Injectable, ViewContainerRef} from '@angular/core'
import {CrudService} from '../crud-service'
import {UserService} from '../user/user-service'
import {IframeDataService} from '../iframe-data.service'
import {DONE_EDITING_FOOTER} from '../../cores/builder/constants/pb-constant'
import {SubjectsService} from '../subjects.service'
import {ModalPopupService} from '../../cores/modal/service/modal.service'
import {SocialMediaEditorComponent} from '../../components/social-media-editor/social-media-editor.component'

@Injectable()
export class WebsiteSettingsService {

    config: any
    protected _websiteSettingModel: string = 'webconfig'

    constructor(private _crudService: CrudService,
                private _iframeService: IframeDataService,
                private _subjectService: SubjectsService,
                private _modalService: ModalPopupService,
                private _userService: UserService) {

    }

    async update(content: any) {
        this.config = content
        await this._crudService.PUT(this._websiteSettingModel, this.config.id, this.config)
        this._subjectService.$setSubject('DATA_UPDATED', true)
        this._iframeService.listenMessage(DONE_EDITING_FOOTER, true)
    }


    updateFooter(content: any) {
        if (!this.config.footer) this.config.footer = {}
        this.config.footer[this._userService.language] = content
        this.update(this.config)
    }


    async editSocialMedia(viewRef: ViewContainerRef) {
        const socialMediaEditor: SocialMediaEditorComponent = await this._modalService.createModal(viewRef, SocialMediaEditorComponent)
        socialMediaEditor.socialMedia = this.config.social_media
        socialMediaEditor.updateButton.nativeElement.addEventListener('click', async () => {
            socialMediaEditor.busy = true
            await this.update(this.config)
            this._subjectService.$setSubject('social_media_updated', this.config)
            socialMediaEditor.busy = false
        })
        socialMediaEditor.buttonClose.nativeElement.addEventListener('click', async () => {
            this._modalService.closeModal()
        })
    }

}
