import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core'
import {TimeoutWait} from '../../../../../helpers/helper-async-timeout'
import { CrudService } from '../../../../../services/crud-service';
import { CreatorExtendCore } from '../../../extend/card-extend-core';
import { UserService } from '../../../../../services/user/user-service';

@Component({
    selector: 'vold-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent extends CreatorExtendCore implements OnInit, AfterViewInit {

    @Input() model: any

    @ViewChild('closeButton') closeButton: ElementRef

    @ViewChild('saveUpdateButton') saveUpdateButton: ElementRef

    isActive: boolean

    busy: any
    updateComplete: any
    language: any

    constructor(private _crudService: CrudService,
                public userService: UserService, public ref : ElementRef) {
        super(userService, ref)
    }


    async extendAfterViewInit() {

        await TimeoutWait(400)
        this.isActive = true

    }
    


    async updateImage() {
        this.busy = true
        const newUpdate: any = await this._crudService.PUT(`picture`, this.model.id, this.model)

        let title = 'Image'

        if (newUpdate.title) {
            title = newUpdate.title[this.language] || newUpdate.file_name
        }

        this.updateComplete = `'${title}' is updated!`
        this.busy = false

        await TimeoutWait(5000)
        this.updateComplete = ''
    }

}
