import {TemplateModel} from './template-model'

export function templateK01Render(option: TemplateModel) {


    const socialMedia = () => {

        let socmed = ''

        if (option.socialNetworks) {
            for (let i = 0; i < option.socialNetworks.length; i++) {
                const item = option.socialNetworks[i]
                socmed += `<li><a href="${item.url}"><img width="25" src="${item.image}" alt="${item.title}"></a></li>`
            }
        }

        return socmed

    }


    return `<table border="0" cellpadding="0" cellspacing="0" class="body">
    <tr>
        <td>&nbsp;</td>
        <td class="container">
            <div class="content">

                <!-- START CENTERED WHITE CONTAINER -->
                <span class="preheader"></span>
                <table class="main">

                    <!-- START HEADER -->
                    <tr>
                        <td class="wrapper">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <a href="">
                                            <img width="200" src="http://kopernik.dev.fleava.com/assets/images/static/kopernik-logo-black.png" alt="Kopernik Logo">
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <a href="">
                                            <img width="100%" src="${option.banner}" alt="Kopernik Banner">
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- START MAIN CONTENT AREA -->
                    <tr>
                        <td class="wrapper">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        ${option.content}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- END MAIN CONTENT AREA -->
                </table>

                <!-- START FOOTER -->
                <div class="footer">
                    <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                            <td class="content-block">
                                ${option.socialNetworksTitle}
                                <ul>
                                    ${socialMedia()}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block powered-by">
                                ${option.footer}
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- END FOOTER -->

                <!-- END CENTERED WHITE CONTAINER -->
            </div>
        </td>
        <td>&nbsp;</td>
    </tr>
</table>`

}
