import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'


export const VIP_DASHBOARD_NAVIGATION_EDITOR: FormModelSchema[] = [
    {
        title: 'Active',
        component: 'SlideToggleComponent',
        slideToggle: {
            values: [true, false]
        },
        model: 'is_active',
        blockSize: 6,
    },

    {
        title: 'Disable Create Button',
        component: 'SlideToggleComponent',
        slideToggle: {
            values: [true, false]
        },
        model: 'disabled_create',
        blockSize: 6,
    },

    {
        title: 'Navigation Title',
        component: 'InputComponent',
        model: 'data.title',
        blockSize: 4
    },

    {
        title: 'Path Route',
        component: 'InputComponent',
        model: 'path',
        blockSize: 4
    },

    {
        title: 'Nav Icon',
        component: 'InputComponent',
        model: 'data.icon',
        blockSize: 4
    },


    {
        title: 'Heading Title',
        component: 'InputComponent',
        model: 'data.headingTitle',
        blockSize: 4
    },

    {
        title: 'Table Option',
        component: 'InputComponent',
        model: 'data.tableSetting',
        blockSize: 4
    },

    {
        title: 'Category',
        component: 'InputComponent',
        model: 'data.category',
        blockSize: 4
    },

    {
        title: 'Restrict for Roles',
        component: 'CheckBoxComponentComponent',
        model: 'data.role',
        checkBox: {
            remote: true,
            apiModel: {
                path: 'role'
            },
            label: 'title',
            setValueModal: 'title'
        },
        callBackOnInit(self) {
            if (self.formDetail.checkBox.remote) self.formDetail.checkBox.apiModel.params = {
                client_id: self.model.client_id
            }

        }
    },


    {
        title: 'Live Editor',
        component: 'SlideToggleComponent',
        slideToggle: {
            values: [true, false]
        },
        model: 'data.live_editor',
        blockSize: 6,

    },

    {
        title: 'Card View Layout',
        component: 'SlideToggleComponent',
        slideToggle: {
            values: ['card', 'table']
        },
        model: 'data.list_layout',
        blockSize: 6,
    },

    {
        title: 'Route Name',
        component: 'MultipleAddComponent',
        model: 'data.route_name',
        blockSize: 12,
        multipleAdd: {
            blockSize: 6,
            buttonTitle: 'item'
        },
        multipleComponentToUse: [
            {
                title: 'Language',
                component: 'InputComponent',
                model: 'lang',
                blockSize: 6,
            },
            {
                title: 'Name',
                component: 'InputComponent',
                model: 'name',
                blockSize: 6,
            },
        ]
    }
]
