import {Component, ElementRef, OnInit} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {FileUploadService} from '../../../../services/file-manager/file-upload.service'
import {UserService} from '../../../../services/user/user-service'
import {NotifDialogService} from '../../../../services/notif-service'

@Component({
    selector: 'vold-button-file-avatar',
    templateUrl: './button-file-avatar.component.html',
    styleUrls: ['./button-file-avatar.component.scss'],

})

export class ButtonFileAvatarComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    background: any
    busy: boolean

    constructor(public ref: ElementRef,
                private _uploadService: FileUploadService,
                private _notifService: NotifDialogService,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {
        this.background = this.modelAlias[this.inputId]
    }

    async onFileChange(file: any) {

        this.busy = true

        /**
         * Checking file
         * @type {any}
         */
        const isSecure = await this._uploadService.imageRestricted(file, 'image')

        if (!isSecure) return this.busy = false


        const fileUpload = await this._uploadService.uploadFile({
            type: this.formDetail.buttonFileAvatar.folderTarget,
            file: file
        }, 'image')

        if (fileUpload.files.length === 0) {
            this.busy = false

            this._notifService.showDialog({
                type: 'error',
                message: `Failed to upload image, please try again!`
            })

            return
        }


        this._notifService.showDialog({
            type: 'success',
            message: `Image uploaded successfully!`
        })

        this.background = fileUpload.files[0].path
        this.setModelValue(fileUpload.files[0], this.formDetail.model)

        this.busy = false
    }

}
