import {Pipe, PipeTransform} from '@angular/core'
import {htmlToPlaintext} from '../helpers/helper-dom'

@Pipe({
    name: 'htmlToPlain'
})
export class HtmlToPlainPipe implements PipeTransform {

    transform(value: string, maxText?: number, emailTrim?: boolean): any {

        let newText = htmlToPlaintext(value)


        if (maxText !== undefined) {

            try {
                if (newText.length > maxText) {
                    let trimEnd = newText.lastIndexOf(' ')
                    newText = newText.substr(0, maxText)

                    if (emailTrim) trimEnd = newText.lastIndexOf('@')


                    newText = newText.substr(0, Math.min(newText.length, trimEnd)) + '...'
                }
            } catch (err) {
                newText = '-'
            }
        }


        return newText
    }

}
