import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core'
import {CrudService} from '../../../services/crud-service'
import {CardLayoutSchema} from '../../../models/dynamic-compopent/card-model'
import {ActivatedRoute, Router} from '@angular/router'
import {ModalPopupService} from '../../../cores/modal/service/modal.service'
import {InstanceViewComponent} from '../instance-view/instance-view.component'
import {CreateCardComponent} from '../../../cores/creators/card/create-card.component'
import {NotifDialogService} from '../../../services/notif-service'
import {CreateNewPostService} from '../../../services/create/create-new-post.service'
import {CreateCardComponentService} from '../../../services/create/create-card-component.service'
import {TimeoutWait} from '../../../helpers/helper-async-timeout'
import {UserService} from '../../../services/user/user-service'
import {EditorService} from '../../../services/editor/editor-service'
import {generateParamModelQuery} from '../../../helpers/functions/functions'
import {defaultCardModel} from '../../../cards/card-layout-model/default/default-card'
import {CreatePostTypeComponent} from '../../create-post-type/create-post-type.component'
import {SubjectsService} from '../../../services/subjects.service'
import {CloneDeepData} from '../../../helpers/helper-dom'

@Component({
    templateUrl: './layout-card-view.component.html',
    styleUrls: ['./layout-card-view.component.scss']
})
export class LayoutCardViewComponent implements OnInit, AfterViewInit, OnDestroy {

    isLoaded: boolean
    cardDetail: CardLayoutSchema
    routeDetail: any
    private _sub: any = []

    hasData: boolean
    @ViewChild(CreateCardComponent) creator: CreateCardComponent

    /**
     * Set true if non page is set as live editor
     * @type {boolean}
     */
    isLiveEditor: boolean = false


    constructor(private _crudService: CrudService,
                private _router: Router,
                private _userService: UserService,
                private _createPost: CreateNewPostService,
                private _notifDialog: NotifDialogService,
                private _viewRef: ViewContainerRef,
                private _editorService: EditorService,
                private _modalService: ModalPopupService,
                private _activatedRoute: ActivatedRoute,
                private _subjectService: SubjectsService,
                private _factoryResolver: ComponentFactoryResolver,
                private _application: ApplicationRef,
                private _injector: Injector,
                private _createCard: CreateCardComponentService) {
    }

    async ngOnInit() {

        /**
         * Empty data
         * @type {any[]}
         */
        this._createCard.cardData = []

        this._sub.push(
            this._activatedRoute.data.subscribe(async (res: any) => {

                if (!res.cardDetail) return

                if (res.cardDetail.apiModel.path === 'post') this._editorService.pageType = 'post'
                else this._editorService.pageType = 'page'

                /**
                 * Use by post
                 */
                if (res.live_editor) this.isLiveEditor = res.live_editor

                /**
                 * Make sure not us card table layout detail
                 */
                res.cardDetail.model = defaultCardModel

                /**
                 * New update
                 * @type {CardLayoutSchema | CardModelSchema | any}
                 */
                this.cardDetail = res.cardDetail


                /**
                 * Cache in memory
                 * @type {CardLayoutSchema}
                 */
                this._createCard.cardDetail = this.cardDetail

                if (!res.cardDetail) return this.isLoaded = true
                this.routeDetail = res

                /**
                 * Get model data
                 * @type {ArrayBuffer}
                 */
                const cardData: any = await this._getCardData()
                if (cardData.results.length > 0) this.hasData = true

                /**
                 * Set card data
                 */
                this._createCard.cardData = cardData.results

                this.isLoaded = true
            })
        )

    }

    ngAfterViewInit() {

        this._sub.push(
            this._createPost.$getNewPost().subscribe(res => {
                this.createNewCards(res)
            })
        )

    }


    private _getCardData() {

        const api = this._createCard.cardDetail.apiModel

        /**
         * Create query params
         * @type {{limit: number}}
         */
        let setParams = generateParamModelQuery(api, this._createCard.cardDetail.apiModel.limit)

        if (api.params) setParams = Object.assign({}, setParams, api.params)

        return this._crudService.GET(`${this._createCard.cardDetail.apiModel.path}`, {
            params: setParams
        })

    }


    /**
     * Manage page editor
     * @param data
     * @returns {Promise<void>}
     */
    async editor(data: any) {

        if (this.cardDetail.editor && this.cardDetail.editor.instance) {
            await this.quickEditContent(data)
        } else {
            /**
             * editor for post
             */
            if (this._createCard.cardDetail.apiModel.post_type && this.isLiveEditor) {

                this.fullEditor(data, {
                    queryParams: {
                        type: 'post',
                        post_type: this._createCard.cardDetail.apiModel.post_type,
                        original_route_parent: `${this._router.url}`
                    }
                })

            } else {
                this.fullEditor(data)
            }

        }

    }

    async quickEditContent(data: any) {
        this._editorService.data.singleData = data

        const comp = await this._modalService.injectAtRoot(CreatePostTypeComponent, this._factoryResolver, this._application, this._injector)
        await comp.component.instance.show(true)
        this._subjectService.pushSubscription(
            comp.component.instance.onClosed.subscribe(() => {
                this._modalService.detachView(comp.container)
            })
        )
    }


    fullEditor(data: any, params?: any) {
        let pathEditor = ''
        if (this.cardDetail.editor && this.cardDetail.editor.prefixPath) {
            pathEditor = this.cardDetail.editor.prefixPath
        } else {
            pathEditor = '/p/web/pages/edit'
        }

        this._router.navigate([`${pathEditor}/${data.id}`], params)
        this._editorService.data.singleData = data
    }

    async duplicateData(data?: any) {

        const duplicatedData = CloneDeepData(data)
        delete duplicatedData.id

        if (duplicatedData && duplicatedData.status) duplicatedData.status = 'draft'
        if (duplicatedData && duplicatedData.title) this._duplicateData(duplicatedData.title)
        if (duplicatedData && duplicatedData.slug) delete duplicatedData.slug
        const duplicatedPost = await this._crudService.POST(this._editorService.pageType, duplicatedData)
        if (duplicatedPost) await this.editor(duplicatedPost)
    }

    private _duplicateData(data: Object, isSlug: boolean = false) {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (isSlug) data[key] = `duplicated-${data[key]}`
                else data[key] = `Duplicated ${data[key]}`
            }
        }
    }

    /**
     * Delete card
     * @param data
     * @returns {Promise<void>}
     */
    async delete(data, index: number) {

        let titlePost: string = data.name
        if (data.title && data.title[this._userService.language]) titlePost = data.title[this._userService.language]

        this._notifDialog.showDialog({
            type: 'confirm',
            message: `Are you sure want to delete this <b>${titlePost}</b>?`,
            confirmFn: async (isConfirm: boolean) => {

                if (isConfirm) {
                    const deletedItem: any = await this._crudService.DELETE(this.cardDetail.apiModel.path, data.id)
                    this.creator.deleteItem(index)
                    await TimeoutWait(500)
                    this._notifDialog.showDialog({
                        type: 'success',
                        message: `Successfully delete post ${titlePost}`,
                        timeOutClose: 4500
                    })
                }
            }
        })
    }

    async instanceView(data: any) {

        const comp: any = await this._modalService.createModal(this._viewRef, InstanceViewComponent)
        comp.data = data
        comp.viewDetail = this.cardDetail.model.instanceView
    }

    createNewCards(data: any) {
        if (data.results.length === 0) return
        this._createCard.$setCardData(data)
    }


    ngOnDestroy() {
        this._sub.forEach(sub => {
            sub.unsubscribe()
        })
    }

}
