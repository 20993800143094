import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core'
import {TimeoutWait} from '../helpers/helper-async-timeout'

import * as UUID from 'uuid'

@Directive({
    selector: '[voldCardStaggerAnimation]'
})
export class CardStaggerAnimationDirective implements OnInit, AfterViewInit {

    id = UUID()

    children: any = []

    @Input('delay') delay: number
    @Input('animation') animation: string = 'stagger-up'

    constructor(private _el: ElementRef) {

    }

    ngOnInit() {
        this._el.nativeElement.classList.add('will-animate', this.animation, 'disabled-container')
    }


    async ngAfterViewInit() {
        await TimeoutWait(700 + this.delay)
        this._el.nativeElement.classList.add('active')
        this._el.nativeElement.classList.remove('disabled-container')
    }

}
