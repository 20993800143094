import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormsModule} from '@angular/forms'
import {InputComponent} from './input/input.component'
import {TextareaComponent} from './text-area/textarea.component'
import {TextEditorComponent} from './text-editor/text-editor.component'
import {DirectivesBundleModule} from '../../../directives/directives-bundle.module'
import {SelectOptionComponent} from './select-option/select-option.component'
import {MultipleAddComponent} from './multiple/multiple-add.component'
import {LooperComponent} from './looper/looper.component'
import {CardCreatorModule} from '../card/create-card.module'
import {ButtonFileComponent} from './button-file/button-file.component'
import {SlideToggleComponent} from './slide-toggle/slide-toggle.component'
import {ButtonFileAvatarComponent} from './button-file-avatar/button-file-avatar.component'
import {PipesModule} from '../../../pipes/pipes.module'
import {ButtonSubmitComponent} from './button-submit/button-submit.component'
import {SingleImageModule} from '../card-component/single-image/single-image.module'
import {GoogleMapModule} from './google-map/google-map.module'
import {InputCalendarComponent} from './input-calendar/input-calendar.component'
import {CalendarModule} from '../../../components/calendar/calendar.module'
import {EmailTemplateComponent} from './email-template/email-template.component'
import {TextDisplayComponent} from './text-display/text-display.component'
import {CheckBoxComponentComponent} from './check-box-component/check-box-component.component'
import {InputTimeComponent} from './input-time/input-time.component'
import {LanguagesComponent} from './languages/languages.component'
import {VirtualScrollModule} from 'angular2-virtual-scroll'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesBundleModule,
        CardCreatorModule,
        PipesModule,
        SingleImageModule,
        GoogleMapModule,
        CalendarModule,
        VirtualScrollModule
    ],

    declarations: [
        InputComponent,
        TextareaComponent,
        TextEditorComponent,
        SelectOptionComponent,
        MultipleAddComponent,
        LooperComponent,
        ButtonFileComponent,
        SlideToggleComponent,
        ButtonFileAvatarComponent,
        ButtonSubmitComponent,
        InputCalendarComponent,
        EmailTemplateComponent,
        TextDisplayComponent,
        CheckBoxComponentComponent,
        InputTimeComponent,
        LanguagesComponent
    ],

    exports: [
        InputComponent,
        TextareaComponent,
        TextEditorComponent,
        SelectOptionComponent,
        MultipleAddComponent,
        LooperComponent,
        ButtonFileComponent,
        SlideToggleComponent,
        ButtonFileAvatarComponent,
        ButtonSubmitComponent,
        InputCalendarComponent,
        GoogleMapModule,
        EmailTemplateComponent,
        TextDisplayComponent,
        CheckBoxComponentComponent,
        InputTimeComponent,
        LanguagesComponent
    ],

    entryComponents: [
        InputComponent,
        TextareaComponent,
        TextEditorComponent,
        SelectOptionComponent,
        MultipleAddComponent,
        LooperComponent,
        ButtonFileComponent,
        SlideToggleComponent,
        ButtonFileAvatarComponent,
        ButtonSubmitComponent,
        InputCalendarComponent,
        EmailTemplateComponent,
        TextDisplayComponent,
        CheckBoxComponentComponent,
        InputTimeComponent,
        LanguagesComponent
    ]
})

export class FormComponentsCreatorModule {

}
