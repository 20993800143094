export class TextDisplayModel {
    /**
     * count, sum, link, assets
     */
    type?: string
    prefixText?: string
    suffixText?: string
    hideBorderBottom?: boolean
    classStyle?: string
}
