import {Injectable} from '@angular/core'
import {UserService} from './user/user-service'

@Injectable()
export class GlobalService {
    constructor(private _userService: UserService) {
    }

    setStaticLanguageValue(checker: any) {

        const languages = this._userService.websiteSettings.language
        let staticValue: string = ''
        let isUntranslated: boolean = false

        if (checker && checker[this._userService.language]) staticValue = checker[this._userService.language]
        else {
            isUntranslated = true
            if (checker && checker['en']) staticValue = checker['en']
            languages.map(l => {
                const lang = l.code.toLowerCase()
                if (lang !== this._userService.language) if (checker && checker[lang]) staticValue = checker[lang]
            })
        }
        return staticValue
    }
}
