import {Injectable} from '@angular/core'
import {EditorDataService} from './editor-data-service'
import {CrudService} from '../crud-service'
import {EditorComponentService} from './editor-component-service'

@Injectable()
export class EditorService {

    watcher: any = []
    currentEditedPage: string
    pageType: string = 'page'

    constructor(public data: EditorDataService,
                public component: EditorComponentService,
                private _crudService: CrudService) {
    }

    async getSingle(resolve: any, keyState?: any) {


        if (keyState) {
            this.data.singleData = await this._crudService.GET(`${resolve.model}?${keyState}=${resolve.id}`)
        } else {

            if (!this.data.singleData) {
                this.data.singleData = await this._crudService.GET(`${resolve.model}/${resolve.id}`)
            }

            if (this.data.singleData && this.data.singleData.id !== resolve.id) {
                this.data.singleData = await this._crudService.GET(`${resolve.model}/${resolve.id}`)
            }
        }

        const data = this.data.singleData
        this.data.$setSingleDetail(data)
        return data

    }

}
