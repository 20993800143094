import {CardSimpleComponent} from './card-simple/card-simple.component'
import {FeaturedCardComponent} from './featured/featured.component'
import {CardUserComponent} from './user/card-user.component'
import {CardSingleLogoComponent} from './card-single-logo/card-single-logo.component'
import {CardMediaComponent} from './card-media/card-media.component'
import {CardFileDocComponent} from './card-file-doc/card-file-doc.component'
import {CardTableComponent} from './card-table/card-table.component'
import {CardFeaturedWithTextComponent} from './card-featured-with-text/card-featured-with-text.component'
import {SingleImageComponent} from './single-image/single-image.component'
import {CardPlainTextComponent} from './card-plain-text/card-plain-text.component'

export const CARD_COMPONENT = {
    CardSimpleComponent,
    FeaturedCardComponent,
    CardUserComponent,
    CardSingleLogoComponent,
    CardMediaComponent,
    CardFileDocComponent,
    CardTableComponent,
    CardFeaturedWithTextComponent,
    SingleImageComponent,
    CardPlainTextComponent
}
