import {InputComponent} from './input/input.component'
import {TextareaComponent} from './text-area/textarea.component'
import {TextEditorComponent} from './text-editor/text-editor.component'
import {SelectOptionComponent} from './select-option/select-option.component'
import {MultipleAddComponent} from './multiple/multiple-add.component'
import {LooperComponent} from './looper/looper.component'
import {ButtonFileComponent} from './button-file/button-file.component'
import {SlideToggleComponent} from './slide-toggle/slide-toggle.component'
import {ButtonFileAvatarComponent} from './button-file-avatar/button-file-avatar.component'
import {ButtonSubmitComponent} from './button-submit/button-submit.component'
import {GoogleMapComponent} from './google-map/google-map.component'
import {InputCalendarComponent} from './input-calendar/input-calendar.component'
import {EmailTemplateComponent} from './email-template/email-template.component'
import {TextDisplayComponent} from './text-display/text-display.component'
import {CheckBoxComponentComponent} from './check-box-component/check-box-component.component'
import {InputTimeComponent} from './input-time/input-time.component'
import {LanguagesComponent} from './languages/languages.component'

export const FORM_COMPONENT = {
    InputComponent,
    TextareaComponent,
    TextEditorComponent,
    SelectOptionComponent,
    MultipleAddComponent,
    LooperComponent, // Not yet
    ButtonFileComponent,
    SlideToggleComponent,
    ButtonFileAvatarComponent,
    ButtonSubmitComponent,
    GoogleMapComponent,
    InputCalendarComponent,
    EmailTemplateComponent,
    TextDisplayComponent,
    CheckBoxComponentComponent,
    InputTimeComponent,
    LanguagesComponent
}
