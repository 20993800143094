import {Component, Input, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../../cores/creators/extend/card-extend-core'
import {InstanceViewModel} from './instance-view-model'
import {ModalPopupService} from '../../../cores/modal/service/modal.service'
import {UserService} from '../../../services/user/user-service'

@Component({
    selector: 'vold-instance-view',
    templateUrl: './instance-view.component.html',
    styleUrls: ['./instance-view.component.scss']
})
export class InstanceViewComponent extends CreatorExtendCore implements OnInit {

    @Input() data: any = {}
    @Input() viewDetail: InstanceViewModel

    imageFeatured: any = []

    constructor(private _modelService: ModalPopupService,
                public userService: UserService) {
        super(userService)
    }

    extendOnInit() {
        this._featuredChecker()

    }


    private _featuredChecker() {

        this.imageFeatured = []
        /**
         * ngModel name
         * @type {string | undefined}
         */
        const featured = this.viewDetail.featured
        const res = this.getModelValueFromData(featured, this.data)

        if (res.length > 0)
            this.imageFeatured.push(...res)
        else
            this.imageFeatured.push(res)

    }


    closeView() {
        this._modelService.closeModal()
    }

}
