import {AfterViewInit, Directive, ElementRef} from '@angular/core'

@Directive({
    selector: '[square], square'
})
export class SquareDirective implements AfterViewInit {

    constructor(private _el: ElementRef) {

    }


    ngAfterViewInit() {

        const container = this._el.nativeElement

        const boxWidth = container.offsetWidth || container.width

        this._el.nativeElement.style.height = `${boxWidth}px`

    }
}
