import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    selector: 'vold-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],

})

export class SlideToggleComponent extends CreatorExtendCore implements OnInit {

    isToggled: boolean

    constructor(public ref: ElementRef,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {

        if (!this.formDetail.slideToggle) return

        if (this.modelAlias[this.inputId] === this.formDetail.slideToggle.values[0]) {
            this.isToggled = true
        }

        

    }

    toggleButton() {

        let valueSelected = 0
        this.isToggled = !this.isToggled
        if (this.isToggled) {
            this.setModelValue(this.formDetail.slideToggle.values[0])
            valueSelected = 0
        }
        else {
            this.setModelValue(this.formDetail.slideToggle.values[1])
            valueSelected = 1
        }


        if (this.formDetail.setModelRelated) {

            this.setModelValue(this.formDetail.setModelRelatedValue[valueSelected], this.formDetail.setModelRelated)
        }

    }

}
