import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_PRODUCT_PRODOTTI: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'product',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 6
                },
                {
                    title: 'Category',
                    component: 'SelectOptionComponent',
                    options: {
                        values: [
                            {
                                id: 'Furniture',
                                title: 'Furniture',
                            },

                            {
                                id: 'Furnishing',
                                title: 'Furnishing',
                            },

                            {
                                id: 'Lighting',
                                title: 'Lighting',
                            }
                        ],
                        value: 'title',
                        defaultValue: 'meta.type'
                    },
                    model: 'meta.type',
                    blockSize: 6
                },
                {
                    title: 'Brands',
                    component: 'InputComponent',
                    model: 'body:lang',
                    blockSize: 6
                },
                {
                    title: 'Link',
                    component: 'InputComponent',
                    model: 'meta.link',
                    blockSize: 6
                },
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        }
    }
}
