import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core'

@Directive({
    selector: '[clickOutsideWatcher]'
})

export class ClickOutsideEventDirective implements AfterViewInit {

    @Input('triggerCondition') triggerCondition: any


    constructor(private _el: ElementRef) {

    }

    ngAfterViewInit() {

    }


}
