import {Component, ElementRef} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    templateUrl: './card-single-logo.component.html',
    styleUrls: ['./card-single-logo.component.scss'],
    host: {
        'class': 'card-single-logo'
    }
})
export class CardSingleLogoComponent extends CreatorExtendCore {

    constructor(private _ref: ElementRef,
                public userService: UserService) {
        super(userService)
        this.host = this._ref
    }

    extendOnInit() {
        this._ref.nativeElement.setAttribute('id', this.model.id)

        if (this.cardDetail.blockSize) {
            this._ref.nativeElement.classList.add(`block-${this.cardDetail.blockSize}`)
        } else {
            this._ref.nativeElement.classList.add(`block-12`)
        }

    }
}
