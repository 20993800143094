import {Component, ElementRef, OnInit, QueryList, ViewChildren, ViewContainerRef} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {CardLayoutSchema} from '../../../../models/dynamic-compopent/card-model'
import {CrudService} from '../../../../services/crud-service'
import {TimeoutWait} from '../../../../helpers/helper-async-timeout'
import {LOOPING} from '../../../../helpers/helper-looping'
import {UserService} from '../../../../services/user/user-service'

@Component({
    templateUrl: './looper.component.html',
    styleUrls: ['./looper.component.scss'],

})

export class LooperComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    cardData: any = []
    card: CardLayoutSchema = {}
    isDataRady: boolean

    @ViewChildren('groupInputHolder', {read: ViewContainerRef}) groupInputHolder: QueryList<ViewContainerRef>

    constructor(private _ref: ElementRef,
                private _crudService: CrudService,
                public userService: UserService) {
        super(userService)
    }

    async ngOnInit() {

        if (this.formDetail.blockSize) {
            this._ref.nativeElement.classList.add(`block-${this.formDetail.blockSize}`)
        } else {
            this._ref.nativeElement.classList.add(`block-12`)
        }



        console.log('this.card', this.card)

        await this.getData()

        this.isDataRady = true

        await TimeoutWait(200)


        // const card = document.getElementById(this.getModelValue(this.formDetail.model)['id'])
        // card.classList.add('selected')

    }


    async getData() {

        let card: any

        if (this.formDetail.looper.card.apiModel) {
            card = await this._crudService.GET(`${this.card.apiModel.path}${this.card.apiModel.params}`)
        } else {

            card = this.getModelValue(this.formDetail.model)

        }
        this.cardData.push(...card)

        console.log('this.cardData', this.cardData)
    }

    selectMedia(data: any) {
        const getCards: any = document.querySelectorAll('.card-single-logo')
        this.setModelValue(data.id)

        console.log('getCards', getCards)

        if (getCards.length === 0) return


        LOOPING(getCards, (card) => {

            if (card.getAttribute('id') !== data.id) {
                card.classList.remove('selected')
            } else {
                card.classList.add('selected')
            }

        })

    }

}
