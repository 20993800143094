export class InstanceViewModel {
    featured?: string

    /**
     * If not provided will use default
     */
    pathEditor?: string
    content?: InstanceViewContentModel
}

export class InstanceViewContentModel {

    title?: string
    description?: string
    status?: string
    others?: string[]
}
