import {Component, ElementRef, OnInit} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {FormModelSchema} from '../../../../models/dynamic-compopent/form-model'
import {CrudService} from '../../../../services/crud-service'
import {EditorDataService} from '../../../../services/editor/editor-data-service'
import {SelectOptionsModelSchema} from './select-option.model'
import {LOOPING} from '../../../../helpers/helper-looping'
import {UserService} from '../../../../services/user/user-service'
import {NotifDialogService} from '../../../../services/notif-service'

@Component({
    selector: 'vold-select-options',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
})

export class SelectOptionComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    private _selectOptionDetail: SelectOptionsModelSchema
    tempArray: any = []
    tempCloneData: any = []
    isLoadingData: boolean

    constructor(public ref: ElementRef,
                private _editorService: EditorDataService,
                private _crudService: CrudService,
                private _notifModal: NotifDialogService,
                public userService: UserService) {
        super(userService, ref)
    }

    async extendOnInit() {
        this._selectOptionDetail = this.formDetail.options
        await this.getOptions()
    }

    extendAfterViewInit() {

        this.sub.push(
            this._editorService.$getRelatedData().subscribe(async (res: any) => {
                if (this.formDetail.related) {
                    await this._checkingForRelatedData(res)
                }
            })
        )
    }


    async getOptions() {

        const formDetail: FormModelSchema = this.formDetail


        if (!formDetail.options) return

        if (formDetail.options.remote) {
            this.isLoadingData = true

            /**
             * Check if data is related to other model
             */
            let params = ''

            if (this.formDetail.related && this.getModelValue(this.formDetail.related)) {
                params = `?${this.formDetail.modelChain}=${this.model[this.formDetail.related].id}`
            } else {
                params = '?limit=9999'
            }

            /**
             * Set query params
             */
            if (formDetail.options.apiModel.params) params = `${params}&${formDetail.options.apiModel.params}`

            const data: any = await this._crudService.GET(`${formDetail.options.apiModel.path}${params}`)
            this.selectOptions = data.results

            this.isLoadingData = false
        } else {
            this.selectOptions = formDetail.options.values
        }

        if (this._selectOptionDetail.multipleSelection) {
            this.formNgModelValue = 'Multiple'

            this.tempArray.push(...this.model[this.formDetail.model])

            // if (this.tempArray.length === 1) this.formNgModelValue = this.getModelValueFromData(formDetail.options.value, this.tempArray[0])


        } else {
            if (!formDetail.options.defaultValue) {


                const value = this.getModelValue(this.formDetail.model)
                this.formNgModelValue = 'Select Option'
                if (value) if (typeof value === 'string') {
                    const checkingId = this.selectOptions.find(opt => opt.id === value)
                    if (checkingId) {
                        this.formNgModelValue = checkingId.title[this.language] || checkingId.title['en']
                    } else {
                        this.formNgModelValue = value
                    }
                }
            } else {
                this.formNgModelValue = this.getModelValue(formDetail.options.defaultValue)
            }
        }


        this.tempCloneData = this.selectOptions

    }


    private async _checkingForRelatedData(relatedId: string) {
        this.isLoadingData = true
        let params = `?${this.formDetail.options.apiModel.params}`

        this.selectOptions = await this._crudService.GET(`${this.formDetail.options.apiModel.path}${params}`, {
            params: {
                [this.formDetail.modelChain]: relatedId,
                limit: 9999
            }
        })
        this.isLoadingData = false
    }

    async showOptions() {

        this.isOptionActive = !this.isOptionActive

    }

    async selectItem(option: any, index: number) {

        /**
         * If multiple selection
         */
        if (this._selectOptionDetail.multipleSelection) {

            const checker: number = await this._checkingDataExist(option.id)

            if (checker > -1) {

                this.tempArray.splice(checker, 1)
                this.model[this.formDetail.model].splice(checker, 1)
            } else {

                this.tempArray.push(this.selectOptions[index])

                this.model[this.formDetail.model].push(this.selectOptions[index].id)

            }

            return false
        }

        /**
         * Single selection
         */
        else {
            this.isOptionActive = false
        }

        this.formNgModelValue = this.setOptionTitle(option)

        if (!this.formDetail.related) this._editorService.$setRelatedData(option.id)

        /**
         * Set model with full item value
         */
        if (this._selectOptionDetail.setValue === 'all') this.setModelValue(option)
        else this.setModelValue(option.id)


        await this._itemSelectedEvent(option)

    }


    private async _itemSelectedEvent(item) {

        if (this.formDetail.options.onItemSelected) {
            const action = this.formDetail.options.onItemSelected

            switch (action.type) {
                case 'POST' :

                    for (let model in action.assignValues) {
                        if (action.assignValues.hasOwnProperty(model)) {
                            action.assignValues[model] = this.model[action.assignValues[model]]
                        }
                    }

                    const create: any = await this._crudService[action.type](action.apiModel, action.assignValues)

                    if (create && create.message === 'success') this._notifModal.showDialog({
                        type: 'success',
                        message: `${action.messageSuccess} <b>${this.setOptionTitle(item)}</b>`
                    })
                    else this._notifModal.showDialog({
                        type: 'error',
                        message: `${action.messageError} <b>${this.setOptionTitle(item)}</b>`
                    })


                    break

                case 'PUT' :
                    this._crudService[action.type](action.apiModel, this.model.id)
                    break
            }
        }

    }


    private _checkingDataExist(id) {

        return new Promise<number>(resolve => {

            let indexData = -1

            LOOPING(this.tempArray, (item, index) => {
                if (item.id === id) {
                    indexData = index
                    return
                }
            })

            resolve(indexData)

        })
    }


    typingSearch(event) {


        if (event.target.value) {
            const searchData = this.tempCloneData.filter(data => data.title[this.userService.language].toLowerCase().indexOf(event.target.value.toLowerCase()) > -1)
            if (searchData.length > 0) this.selectOptions = searchData
        } else {
            this.selectOptions = this.tempCloneData
        }

    }

}
