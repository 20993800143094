import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ɵlooseIdentical
} from '@angular/core'
import * as MediumEditor from 'medium-editor'
import {EditorDataService} from '../services/editor/editor-data-service'
import {TimeoutWait} from '../helpers/helper-async-timeout'

declare var MediumButton: any

@Directive({
    selector: 'medium-editor,[voldMediumEditor]'
})
export class MediumEditorDirective implements OnInit, OnChanges, OnDestroy {

    private lastViewModel: string
    private element: HTMLElement


    editor: any
    @Input('limit') limit: number
    @Input('placeMarkup') placeMarkup: boolean

    @Input('editorModel') model: any = ''
    @Input('editorOptions') options: any = {}
    @Input('editorToolBarButton') toolBar: any = []
    @Input('editorPlaceholder') placeholder: string

    @Output('editorModelChange') update = new EventEmitter()
    @Output('canInsertImage') $canInsertImage = new EventEmitter()


    private _defaultButtonEditor: string[] = [
        'bold',
        'italic',
        // 'h1',
        // 'h2',
        // 'h3',
        // 'orderedlist',
        // 'unorderedlist',
        // 'quote',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'subscript',
        // 'superscript',
        // 'anchor',
        // 'footNote',
        // 'hr'
    ]


    private _sub: any = []

    constructor(private el: ElementRef,
                private _editorDataService: EditorDataService) {
    }

    async ngOnInit() {
        this.element = this.el.nativeElement
        if (this.toolBar.length === 0) this.toolBar = this._defaultButtonEditor

        // console.log('this.toolBar', this.toolBar)

        this.element.innerHTML = `<div class="me-editable">${(this.model === 'undefined' ? '' : this.model) || ''}</div>`

        this._setEditorOption()


        // Global MediumEditor
        this.editor = new MediumEditor('.me-editable', this.options || {})

        await TimeoutWait(200)

        if (!this.limit) return

        const restrictInput = (event) => {

            if (event.type === 'keypress' && event.target.textContent.length >= this.limit) {
                event.preventDefault()
                event.stopPropagation()
            }
        }
        this.editor.on(this.element, 'keypress', restrictInput)
    }

    ngAfterViewInit() {
        this._sub.push(
            this._editorDataService.$getSingleDetail().subscribe(res => {
                this.model = res
            }),

            this._editorDataService.$getImageInserted().subscribe(() => {
                this.updateModel()
            })
        )

        this._sub.push(
            this.editor.subscribe('editableInput', (event) => {
                this._addImageInjectorHolder()
                this.updateModel()
            }),

            this.editor.subscribe('editableKeyup', (event) => {
                this._addImageInjectorHolder()
            }),

            this.editor.subscribe('editableClick', (event) => {
                this._addImageInjectorHolder()
            })
        )
    }

    refreshView() {
        if (this.editor) {
            this.editor.setContent(this.model || '')
        }
    }

    ngOnChanges(changes): void {
        if (this.isPropertyUpdated(changes, this.lastViewModel)) {
            this.lastViewModel = this.model
            this.refreshView()
        }
    }

    private _addImageInjectorHolder() {

        try {

            const currentParagraph = this.editor.getSelectedParentElement()

            const hasContentText = currentParagraph.innerHTML.replace(/<br>/g, '')

            if (!hasContentText) {
                this.$canInsertImage.emit(currentParagraph)
            } else {
                this.$canInsertImage.emit(null)
            }
        } catch (e) {

        }

    }

    private _setEditorOption() {
        /**
         * Sample custom button
         */

        this.options.toolbar = {
            buttons: this.toolBar
        }

        /**
         * Remove any html tag if user paste from any resource
         * @type {{cleanPastedHTML: boolean; cleanAttrs: string[]; cleanTags: string[]; unwrapTags: string[]}}
         */
        this.options.paste = {
            cleanPastedHTML: true,
            cleanAttrs: ['style', 'dir'],
            cleanTags: ['label', 'meta', 'kbd', 'script', 'div'],
            unwrapTags: ['sub', 'sup', 'h2', 'code', 'h1', 'h3', 'h4', 'div', 'strong', 'pre', 'table', 'tr', 'td']
        }


        this.options.anchorPreview = {
            hideDelay: 500,
            previewValueSelector: 'a'
        }

        this.options.extensions = {
            'footNote': new MediumButton({
                    label: 'Foot Note',
                    start: '<div class="foot-note" style="color:#6c6c6c;font-size:12px;margin-bottom:20px;margin-top:20px;">',
                    end: '<hr/></div>'
                }
            ),

            'hr': new MediumButton({
                    label: 'hr',
                    start: '<p>',
                    end: '<hr/></p>'
                }
            )
        }
    }

    /**
     * Emit updated model
     */
    updateModel() {

        let value = this.editor.getContent()

        if (this.placeMarkup) {
            value = value.replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .trim()
        } else {
            value = value.trim()
        }

        this.lastViewModel = value
        this.update.emit(value)
    }

    /**
     * Remove MediumEditor on destruction of directive
     */
    ngOnDestroy(): void {

        this._sub.forEach(sub => {
            sub.unsubscribe()
        })

    }

    isPropertyUpdated(changes, viewModel) {
        if (!changes.hasOwnProperty('model')) {
            return false
        }

        const change = changes.model

        if (change.isFirstChange()) {
            return true
        }
        return !ɵlooseIdentical(viewModel, change.currentValue)
    }
}
