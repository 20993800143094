import {AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import * as $UID from 'uuid/v4'
import {LOOPING} from '../../../../helpers/helper-looping'
import {GoogleMapModel} from './google-map-model'
import {UserService} from '../../../../services/user/user-service'

@Component({
    selector: 'vold-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent extends CreatorExtendCore implements OnInit, AfterViewInit {

    showMarkerList: boolean
    @ViewChild('googleMapContainer') googleMapContainer: ElementRef
    @ViewChild('locationSearch') locationSearch: ElementRef
    @Output('onMarkerDragEnd') onMarkerDragEnd: EventEmitter<any> = new EventEmitter()
    @Input('center') center: number[] = [-8.4554715, 115.071577]

    @Input('mapOptions') mapOptions: any = {
        zoom: 6,
        maxZoom: 18,
        scrollwheel: false
    }

    tempArrayMarker: any = []

    private _modelData: any

    private _singleMarker: any = null

    /**
     * Google map
     */
    private _map: any


    /**
     * Geocoder
     */
    private _geoCoder: any


    /**
     * Bound center map into group location
     */
    private _mapBound: any

    googleMap: GoogleMapModel = new GoogleMapModel()

    constructor(public ref: ElementRef,
                private _zone: NgZone,
                public userService: UserService) {
        super(userService, ref)
    }

    async extendOnInit() {
        this.googleMap = this.formDetail.googleMap
    }

    async extendAfterViewInit() {
        this._initMap()
        // this._mapInit()

    }


    private _initMap() {

        const mapContainer = this.googleMapContainer.nativeElement

        this._setMapOptions()

        this._geoCoder = new google.maps.Geocoder()
        this._map = new google.maps.Map(mapContainer, this.mapOptions)

        this._locations()

        this._googleSearchLocation()

    }

    private _setMapOptions() {
        this.mapOptions = Object.assign({}, this.mapOptions, {
            center: this._mapLatLang(this.center)
        })
    }


    private _locations() {

        const markers = this._modelData = this.getModelValue(this.formDetail.model)
        const markersData = []
        this._mapBound = new google.maps.LatLngBounds()

        if (typeof markers === 'string') {
            markersData.push(markers.split(',').map(Number))
        }


        if (Array.isArray(markers)) {

            LOOPING(markers, marker => {
                markersData.push(marker.split(',').map(Number))
            })

        }


        /**
         * Create marker data
         */
        const renderMarker = new Promise(resolve => (
            LOOPING(markersData, async (latLng, index) => {

                if (isNaN(latLng[0])) latLng[0] = this.center[0]
                if (isNaN(latLng[1])) latLng[1] = this.center[1]

                const latLang = this._mapLatLang(latLng)
                const markerOpts = {
                    indexId: index
                }
                const marker = await this._createMarker(latLang, markerOpts)
                this.tempArrayMarker.push(marker)
                this._mapBound.extend(latLang)
                this._addMarkerIntoMap(marker)

            })
        ))


        /**
         * Wait until all marker complete
         */
        renderMarker.then(() => {
            this._centerMapBound()
        })

    }

    private _mapLatLang(location: number[]) {
        return new google.maps.LatLng(location[0], location[1])
    }

    private _addMarkerIntoMap(marker) {
        marker.setMap(this._map)
    }

    private _removeMarker(marker) {
        marker.setMap(null)
    }

    private async _createMarker(location: any, config: any = {}) {
        const markerId = $UID()

        const options = {
            position: location,
            title: '',
            draggable: true,
            id: markerId,
            address: null
        }

        /**
         * Merge the options
         * @type {google.maps.Marker}
         */
        const marker = new google.maps.Marker(Object.assign({}, options, config))

        await this._getGeoCoderLocation(marker, location)

        /**
         * Add event
         */
        this._markerEventListener(marker)

        return marker
    }


    private _centerMapBound() {
        this._map.fitBounds(this._mapBound)
    }

    centerMapPosition(location) {
        this._map.setCenter(location)
    }

    private _markerEventListener(marker) {

        marker.addListener('dragend', async () => {
            marker.address = 'Loading...'
            marker.address = await this._getGeoCoderLocation(marker, marker.position)
            this._updateDataModel()
        })

    }

    private _getGeoCoderLocation(marker, markerPosition) {
        return new Promise(resolve => {
            this._geoCoder.geocode({'location': markerPosition}, (results, status) => {

                if (status === 'OK') {
                    marker.address = results[0].formatted_address
                    resolve(results[0].formatted_address)
                } else {
                    resolve('Unnamed road')
                }
            })
        })

    }


    private _googleSearchLocation() {

        let autoComplete = new google.maps.places.Autocomplete(this.locationSearch.nativeElement, {
            types: []
        })

        autoComplete.addListener('place_changed', () => {

            this._zone.run(async () => {

                let place: any = autoComplete.getPlace()

                const marker = await this._createMarker(place.geometry.location)

                console.log('marker :', marker)

                if (this.formDetail.googleMap && this.formDetail.googleMap.multipleLocation) {
                    this.tempArrayMarker.push(marker)
                    this._mapBound.extend(place.geometry.location)
                    this._addMarkerIntoMap(marker)
                    this._centerMapBound()

                    /**
                     * Update data value
                     */
                    this._updateDataModel()
                }

                else {

                    if (this.tempArrayMarker.length > 0) this._removeMarker(this.tempArrayMarker[0])
                    this._addMarkerIntoMap(marker)
                    this.centerMapPosition(place.geometry.location)
                    this.tempArrayMarker.shift()
                    this.tempArrayMarker.push(marker)

                    /**
                     * Update data value
                     */
                    this._updateDataModel()
                }
            })
        })
    }

    private _updateDataModel() {

        const newUpdatedData = []

        if (Array.isArray(this._modelData)) {

            this.tempArrayMarker.forEach((marker: any) => {
                const latLangString = `${marker.position.lat()},${marker.position.lng()}`
                newUpdatedData.push(latLangString)
            })

            this.setModelValue(newUpdatedData)

        }

        /**
         * String
         */
        else {
            const newUpdatedData = `${this.tempArrayMarker[0].position.lat()},${this.tempArrayMarker[0].position.lng()}`
            this.setModelValue([newUpdatedData])
        }

    }

    deleteMarker(markerId) {
        this.tempArrayMarker[markerId].setMap(null)
        this.tempArrayMarker.splice(markerId, 1)
        this._centerMapBound()
        this._updateDataModel()
    }

}
