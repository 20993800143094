import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector, Type, ViewContainerRef} from '@angular/core'
import {ModalComponent} from '../modal.component'
import {TimeoutWait} from '../../../helpers/helper-async-timeout'
import {AddComponentToBody} from '../../../helpers/helper-dom'

@Injectable()
export class ModalPopupService {

    appComponentRef: any
    private _modalComponent: any

    constructor(private _factoryResolver: ComponentFactoryResolver,
                private _application: ApplicationRef,
                private _injector: Injector) {

    }


    async injectToBody(component: Type<any>, cb?: any) {
        const injector = AddComponentToBody(this._factoryResolver, this._application, ModalComponent, this._injector)
        const innerContent = await injector.compRef.instance.createModalContainer(component)
        if (cb) cb(injector.compRef, innerContent)
        else this._modalComponent = injector.compRef
        return innerContent
    }

    async createModal(viewContainerRef: ViewContainerRef, component: Type<any>, dataReady: any = {}) {

        const resolver = this._factoryResolver.resolveComponentFactory(ModalComponent)
        const modalComponent = this._modalComponent = viewContainerRef.createComponent(resolver)
        const injectedComponent = await modalComponent.instance.createModalContainer(component)
        document.addEventListener('keyup', (event) => {
            if (event.keyCode === 27) this.closeModal()
        })

        /**
         * Return modal component
         */
        return injectedComponent.instance

    }


    async injectAtRoot(component: Type<any>, resolver?: ComponentFactoryResolver, application?: ApplicationRef, injector?: Injector) {
        const createModal = AddComponentToBody((resolver ? resolver : this._factoryResolver), (application ? application : this._application), ModalComponent, this._injector)
        const innerContent = await createModal.compRef.instance.createModalContainer(component)
        return {
            container: createModal,
            component: innerContent
        }

    }

    async replaceModalComponent(newComponent: Type<any>, confirmWhenClose: any = {}) {
        await this._modalComponent.instance.replaceComponent(newComponent)
        this._modalComponent.instance.closeWithConfirm = confirmWhenClose
        return this._modalComponent
    }


    detachView(container: any) {
        container.application.detachView(container.compRef.hostView)
        container.compRef.destroy()
    }

    async closeModal() {
        this._modalComponent.instance.closeModalComponent()
        await TimeoutWait(400)
        this._modalComponent.destroy()
    }

}
