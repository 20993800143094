import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {CreateCardComponent} from './create-card.component'
import {DirectivesBundleModule} from '../../../directives/directives-bundle.module'

@NgModule({
    imports: [
        CommonModule,
        DirectivesBundleModule,
    ],

    exports: [
        CreateCardComponent
    ],

    declarations: [
        CreateCardComponent
    ]
})
export class CardCreatorModule {
}
