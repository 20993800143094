import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'

@Pipe({
    name: 'safeUrlSource'
})
export class SafeUrlSourcePipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(video: any) {

        return this._sanitizer.bypassSecurityTrustResourceUrl(video)
    }

}
