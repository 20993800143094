import {CardModelSchema} from '../../../models/dynamic-compopent/card-model'
import {AfterViewInit, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Type, ViewChild} from '@angular/core'
import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'
import * as dot from 'dot-object'
import * as $UID from 'uuid/v4'
import * as moment from 'moment'
import {environment} from '../../../../environments/environment'
import {UserService} from '../../../services/user/user-service'
import {TimeoutWait} from '../../../helpers/helper-async-timeout'
import {ActionsEvent} from './actions-event'
import {Vaildator} from '../../vaildator/vaildator'

// declare var dot: any

export class CreatorExtendCore extends ActionsEvent implements AfterViewInit, OnDestroy, OnInit {

    inputId = $UID()

    host: ElementRef
    busy: boolean

    cardIndex: number = 0

    validator: Vaildator = new Vaildator()
    noteValidation: string

    @Input('model') model: any
    @Input() cardDetail: CardModelSchema = new CardModelSchema()
    @Input('formDetail') formDetail: FormModelSchema = new FormModelSchema()
    @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>()

    formNgModelValue: any

    /**replace(',%d', '')
     * Parent class of card creator is called
     */
    callerClass: Type<any>


    /**
     * Enabled/disabled option nav
     */
    isOptionActive: boolean


    @ViewChild('options') options: ElementRef
    @ViewChild('buttonTriggerOption') buttonTriggerOption: ElementRef
    @Output('onDataChange') onDataChange: EventEmitter<any> = new EventEmitter<any>()


    onKeyupChange: EventEmitter<any> = new EventEmitter<any>()

    language: string = 'en'

    selectOptions: FormModelSchema[] | any = []

    /**
     * Held for component that will be use in MultipleAddComponent
     * @type {any[]}
     */
    dynamicMultipleComp: Type<any>[] = []


    sub: any[] = []


    modelAlias: any = {}

    constructor(public userService: UserService,
                public ref?: ElementRef) {
        super()
        this.language = this.userService.storageService.get('language') || 'en'

    }

    async ngOnInit() {
        if (!this.model) this.model = this.userService.editorDataService.singleData
        if (this.formDetail && this.formDetail.inputComponent) {
            if (this.formDetail.inputComponent.min) this.isValid.emit(false)
        }

        this.canShowComponent(this)

        await TimeoutWait(200)

        this.renderModelData()


        if (this.ref) {

            this.ref.nativeElement.classList.add(`block-12`)

            if (this.formDetail.blockSize) {
                this.ref.nativeElement.classList.remove(`block-12`)
                this.ref.nativeElement.classList.add(`block-${this.formDetail.blockSize}`)
            }


            if (this.cardDetail.blockSize) {
                this.ref.nativeElement.classList.remove(`block-12`)
                this.ref.nativeElement.classList.add(`block-${this.cardDetail.blockSize}`)
            }
        }

        this._checkingConditionComponent()

        this.canActivateBy()


        if (this.formDetail.value) this.setModelValue(this.formDetail.value)

        if (this.formDetail.callBackOnInit) this.formDetail.callBackOnInit(this)

        /**
         * Extend parent on init
         */
        this.extendOnInit()
    }


    extendOnInit() {

    }


    async ngAfterViewInit() {

        this.triggerClickOutside()

        this.extendAfterViewInit()
        //
        this.sub.push(
            this.userService.$getLanguage().subscribe(lang => {
                this.language = lang.toLowerCase()
                this.renderModelData()
            }),
        )

        if (this.formDetail.callBackAfterViewInit) this.formDetail.callBackAfterViewInit(this)
    }


    private _checkingConditionComponent() {

        const condition = this.formDetail.conditionShow

        if (condition) {

            const val = this.getModelValue(condition.modelCondition)

            if (condition.conditionValue === false) {

                if (val !== condition.modelValue) {
                    this.ref.nativeElement.style.display = (condition.condition === 'show' ? 'block' : 'none')
                } else {
                    this.ref.nativeElement.style.display = 'none'
                }

            }

            if (condition.conditionValue === true) {

                if (val === condition.modelValue) {
                    this.ref.nativeElement.style.display = condition.condition
                } else {
                    this.ref.nativeElement.style.display = 'none'
                }
            }
        }

    }

    /**
     * Use this method to extend AfterViewInit lifecycle
     */
    extendAfterViewInit() {

    }

    /**
     * Detect outside click for card
     */
    triggerClickOutside() {
        document.addEventListener('click', event => {
            if (!this.buttonTriggerOption) return
            if (this.options && !this.options.nativeElement.contains(event.target) &&
                this.isOptionActive &&
                !this.buttonTriggerOption.nativeElement.contains(event.target)) this.isOptionActive = false
        })
    }


    checkerMultipleValue(value: any, selector?: string) {

        let result = value
        if (typeof value === 'object') {
            result = value[selector]
        }

        return result

    }


    /**
     * This will trigger the method from class where button will do the action
     * @param {string} callback
     */
    cardButtonClicked(callback: string) {

        try {
            this.callerClass[callback](this.model, this.cardIndex)
        } catch (err) {
            console.log('Method not exist')
        }
    }

    viewPdf() {

        if (this.model[this.formDetail.model])
            window.open(`${environment.assets_uri}${this.model[this.formDetail.model].path}`, '_blank')
    }

    activateOption() {
        this.isOptionActive = !this.isOptionActive
    }

    /**
     * Nested object
     * @param {string} objString
     * @param defaultValue | if value undefined use default value instead
     * @returns {any}
     */
    getModelValue(objString: string = this.formDetail.model, defaultValue?: string) {
        let modelValue = this._checkingTextAttribute(objString, this.model)
        if (!modelValue && defaultValue) modelValue = defaultValue
        return modelValue
    }

    getModelValueFromData(objString: string = this.formDetail.model, model: any) {
        return this._checkingTextAttribute(objString, model)
    }


    /**
     * Form input on change value
     * Set model value
     */
    onChange(event: any, model?: any, data?: any) {
        if (this.formDetail.readonly) return // Yes, you can not change any data
        this.setModelValue(event.target.value, model, data)
    }

    onKeyUpChange(event) {

        const val = event.target.value

        if (this.formDetail && this.formDetail.inputComponent) {
            if (this.formDetail.inputComponent.min) {
                if (val) {
                    if (val.length < this.formDetail.inputComponent.min) {
                        this.isValid.emit(false)
                    } else {
                        this.isValid.emit(true)
                    }
                } else {
                    this.isValid.emit(false)
                }
            }
        }

        if (this.formDetail.validation) {

            /**
             * Validate the form
             */
            if (this.formDetail.validation.type) {
                const inValidate: any = this.validator[this.formDetail.validation.type](val)
                if (inValidate.invalid) return this.noteValidation = inValidate.message
                else this.noteValidation = ''
            }


            /**
             * Checking length
             */
            if (this.formDetail.validation.minLengthText) {
                if (val.length < this.formDetail.validation.minLengthText) {
                    this.noteValidation = `Minimum ${this.formDetail.validation.minLengthText} character`
                }
                else this.noteValidation = ''
            }
        }

        this.onChange(event)
    }

    onChangeMediumEditor(value: any, model?: any) {
        if (this.formDetail.readonly) return // Yes, you can not change any data
        this.setModelValue(value, model)
    }


    setModelValue(value: any | string, model: any = this.formDetail.model, data = this.model) {


        const getData = this.getModelValue(model)
        if (!getData && this.formDetail.value) value = value

        if (typeof getData === 'object') dot.remove(model, data)

        /**
         * Remove date if has date format
         */
        try {
            model = model.replace(',%d', '')
        } catch (e) {
            console.log('error data')
        }

        try {
            if (this.formDetail.multipleLanguage)
                model = `${model}.${this.language}`

            if (model.indexOf(':') > -1) {
                model = `${model.replace(':lang', '')}.${this.language}`
                dot.str(model, value, data)
            }

            if (model.indexOf('.') > -1) {
                dot.str(model, value, data)
            }
            else {
                data[model] = value
            }

            this.userService.editorDataService.singleData = this.model
            this.onDataChange.emit(data)

        } catch (e) {
            console.log('error data', e)
        }

    }


    setOptionTitle(title: any) {

        const optionValue = this.formDetail.options.value

        let result = title[optionValue]

        if (optionValue.indexOf(':') > -1) {
            const valueLang = title[optionValue.replace(':lang', '')]
            result = valueLang[this.language] || valueLang['en']
        }

        return result
    }


    private _getModel(objString: string = this.formDetail.model, model: any = this.model) {

        let modelWithLang = objString
        let res = null

        if (!objString) return

        /**
         * If select options items is from remote and default value set
         */
        if (this.formDetail.options && this.formDetail.options.defaultValue) objString = this.formDetail.options.defaultValue
        if (objString.indexOf(':') > -1) modelWithLang = `${objString.replace(':lang', '')}.${this.language}`
        if (this.formDetail.multipleLanguage) modelWithLang = `${objString}.${this.language}`


        try {
            res = dot.pick(modelWithLang, model)
        } catch (err) {

        }

        return res
    }


    private _checkingTextAttribute(objString: string, data?: any) {

        let dataResult: any = ''
        let type = '%s'

        if (!objString) return


        /**
         * Checking value type type
         */
        let getType: any = ''
        //
        if (objString.indexOf(',') > -1) getType = objString.split(',')


        /**
         * For condition value
         */
        if (objString.indexOf('!') > -1) {
            const valueCondition = objString.split('!')

            objString = valueCondition[0] || valueCondition[1]
        }

        /**
         * If has prefix value
         */
        if (objString.indexOf('|') > -1) {
            let newString = objString.split('|')

            if (Array.isArray(getType)) {
                newString = getType[0].split('|')
                type = getType[1]
            }

            dataResult = `${newString[1]} ${this._checkingValueType(newString[0], data, type)}`
        } else {

            type = getType[1]
            dataResult = this._checkingValueType(objString, data, type)
        }

        return dataResult

    }


    private _checkingValueType(val, data, type) {


        let res: any = val

        if (type === '%d') {
            res = moment(this._getModel(val.replace(',%d', ''), data)).format('DD/MM/YYYY')
        } else {
            res = this._getModel(val, data)
        }


        return res

    }

    moreButtonActive(active: boolean) {
        this.ref.nativeElement.classList[active ? 'add' : 'remove']('more-nav-active')
    }


    canActivateBy() {

        const userRole = this.formDetail.userRole
        /**
         * If has roles catch em
         */
        if (userRole) {
            if (userRole.indexOf(this.userService.currentUserRole) < 0) {
                this.ref.nativeElement.style.display = 'none'
            }
        }

    }


    /**
     * New data alias
     */
    renderModelData(dataModel: any = this.model) {

        let model = this.formDetail.model

        if (this.formDetail.condition)
            if (this.getModelValue(this.formDetail.condition.modelCondition)) model = this.formDetail.condition.modelValue

        if (this.cardDetail.featured) model = this.cardDetail.featured

        try {
            if (model.indexOf(':') > -1) model = `${model.replace(':lang', `.${this.language}`)}`

            if (model.indexOf(',') > -1) {
                model = `${model.replace(',%d', '')}`
                const getDate = dot.pick(model, dataModel)
                const data = (getDate ? moment(getDate).format('DD/MM/YYYY') : 'Select Date')
                this.modelAlias[this.inputId] = data.trim()
            } else {
                const data: any = dot.pick(model, dataModel)

                this.modelAlias[this.inputId] = data || this.formDetail.value
            }
        } catch (e) {

        }
    }

    extendOnDestroy() {

    }

    ngOnDestroy() {
        this.sub.forEach((sub: any) => {
            sub.unsubscribe()
        })


        this.extendOnDestroy()
    }
}

