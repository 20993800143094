import {Injectable} from '@angular/core'
import {CrudService} from '../crud-service'
import {Subject} from 'rxjs/Subject'
import {StorageService} from '../storage-service'
import {EditorDataService} from '../editor/editor-data-service'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Injectable()
export class UserService {

    user: any = {}
    /**
     * Selected language
     * @type {string}
     */
    language: string = 'en'
    currentUserRole: string = 'team'
    websiteSettings: any
    websiteLanguages: string[] = []
    clientDashboardConfig: any = {}

    /**
     * This use when template is front page
     * @type {boolean}
     */
    liveEditing: boolean = true


    private _$listenLanguageChange: Subject<string> = new Subject<string>()

    private _$listenSelf: Subject<any> = new Subject<any>()

    constructor(private _crudService: CrudService,
                public editorDataService: EditorDataService,
                public storageService: StorageService) {

    }


    async checkSelf() {

        let validUser: boolean = false

        const tokenCheck = {
            token: this.storageService.get('auth'),
        }

        await TimeoutWait(200)
        const self: any = await this._crudService.GET('g/self', tokenCheck)

        this.currentUserRole = self.role.title
        if (!self.success) this.$setSelf(self)

        return validUser

    }

    $setSelf(value: any) {
        this.currentUserRole = value.role.title
        this.user = value
        this._$listenSelf.next(value)
    }


    $setLanguage(val: string = 'en') {
        this.language = val
        this.storageService.set('language', val)
        this._$listenLanguageChange.next(val)
    }

    $getLanguage() {
        return this._$listenLanguageChange.asObservable()
    }

    $watchSelf() {
        return this._$listenSelf.asObservable()
    }


    getClientId() {
        return this.storageService.get('client_id')
    }

    async userDataAllowedPost() {

        return this.user

    }


}
