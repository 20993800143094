import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'languageDetection'
})
export class LanguageDetectionPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any, language: string, message: string = 'No content!') {

        let render = value

        if (value && typeof value === 'object') render = value[language] || message
        else render = value

        return render
    }

}
