import { Injectable } from "@angular/core";
import { CrudService } from "../crud-service";

@Injectable()
export class NavigationService {
    navigation: any[] = [];
    selectedNavigationDetail: any;

    constructor(private _crudService: CrudService) {}

    async getNavigation() {
        const navigation: any = await this._crudService.GET("navigation", {
            limit: 99999,
            parentId: null
        });

        this.navigation = navigation.results;

        return this.navigation;
    }

    async getNavigationAtPosition(position: string) {
        let navigation: any[] = null;

        await this.getNavigation();

        this.navigation.map(nav => {
            if (nav.position === position) {
                this.selectedNavigationDetail = nav;
                navigation = [];
                navigation.push(...nav.order);
            }
        });

        return navigation;
    }

    async createNavigation(position: string, title: string) {
        let body = {
            title: title,
            position: position,
            status: "published"
        };

        const navigation: any = await this._crudService.POST(
            "navigation",
            body
        );
        this.navigation.push(navigation);
        this.selectedNavigationDetail = navigation;

        return this.navigation;
    }

    updateNavigation(navigation: any[]) {
        this._crudService.PUT("navigation", this.selectedNavigationDetail.id, {
            order: navigation
        });
    }
}
