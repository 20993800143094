import {Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef} from '@angular/core'
import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'
import {MultipleAddComponent} from '../form-components/multiple/multiple-add.component'
import {FORM_COMPONENT} from '../form-components/form-components.const'

@Component({
    selector: 'vold-form-creator',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent {

    formChooser = FORM_COMPONENT

    @Input('model') model
    @Input('name') name: string
    @Output('onDataChange') onDataChange: EventEmitter<any> = new EventEmitter<any>()
    @Output('isValid') isValid: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() formDataArc: FormModelSchema[]
    @ViewChild('componentInjector', {read: ViewContainerRef}) componentInjector: ViewContainerRef
    @ViewChild('multipleDynamicComponent') multipleDynamicComponent: MultipleAddComponent


    onDataSet(data) {
        this.onDataChange.emit(data)
    }

    validateInput(valid) {
        this.isValid.emit(valid)
    }

}
