import {Component, ElementRef, OnInit} from '@angular/core'

import * as $UID from 'uuid/v4'
import {UserService} from '../../../../services/user/user-service'
import {CreatorExtendCore} from '../../extend/card-extend-core'

@Component({
    selector: 'vold-input-component',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],

})

export class InputComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()

    constructor(public ref: ElementRef,
                public userService: UserService) {
        super(userService, ref)
    }
}

