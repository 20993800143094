import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    templateUrl: './card-user.component.html',
    styleUrls: ['./card-user.component.scss'],
})
export class CardUserComponent extends CreatorExtendCore implements OnInit {
    constructor(private _ref: ElementRef,
                public userService: UserService) {
        super(userService)
        this.host = this._ref
    }

    extendOnInit() {
        this._ref.nativeElement.setAttribute('id', this.model.id)
    }

}
