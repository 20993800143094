import {
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {SingleImageModel} from './single-image-model'
import {TimeoutWait} from '../../../../helpers/helper-async-timeout'
import {UserService} from '../../../../services/user/user-service'
import {EditorDataService} from '../../../../services/editor/editor-data-service'
import {FileUploadService} from '../../../../services/file-manager/file-upload.service'
import {AddComponentToBody} from '../../../../helpers/helper-dom'
import {ImageEditorComponent} from './image-editor/image-editor.component'

@Component({
    selector: 'vold-single-image',
    templateUrl: './single-image.component.html',
    styleUrls: ['./single-image.component.scss']
})
export class SingleImageComponent extends CreatorExtendCore implements OnInit, OnChanges {

    @Output('deleteImage') deleteImage: EventEmitter<any> = new EventEmitter<any>()
    @Input('showDeleteButton') showDeleteButton: boolean = false
    @Input('imageSize') imageSize: string

    image: any
    singleImage: SingleImageModel
    busy: boolean
    showImage: boolean

    constructor(public ref: ElementRef,
                public userService: UserService,
                private _editorDataService: EditorDataService,
                private _factory: ComponentFactoryResolver,
                private _application: ApplicationRef,
                private _fileUpload: FileUploadService,
                private _injector: Injector) {
        super(userService, ref)
    }

    extendOnInit() {
        this.singleImage = this.cardDetail.singleImage
        this.image = this.getModelValue(this.cardDetail.featured)
    }


    ngOnChanges(change) {
        this.image = change.model.currentValue
        this.renderModelData()
    }


    async extendAfterViewInit() {
        await TimeoutWait(900)
        this.showImage = true
    }


    delete(cbFn: string) {
        this.deleteImage.emit(this.model)
        this.cardButtonClicked(cbFn)
    }


    async editImage() {
        const imageEditor = AddComponentToBody(this._factory, this._application, ImageEditorComponent, this._injector)
        imageEditor.compRef.instance.model = this.model
        imageEditor.compRef.instance.closeButton.nativeElement.addEventListener('click', async () => {
            imageEditor.compRef.instance.isActive = false
            await TimeoutWait(500)
            imageEditor.compRef.destroy()
        })
    }
}
