import {Component} from '@angular/core'
import {LANGS} from './languages-json'

@Component({
    selector: 'vold-languages-input-component',
    templateUrl: './languages-template.component.html',
    styleUrls: ['./languages-style.component.scss']
})

export class LanguagesComponent {

    languagesISO = LANGS

}
