import {Component, ElementRef, Input, ViewChild} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    selector: 'arv-card-table',
    templateUrl: './card-table.component.html',
    styleUrls: ['./card-table.component.scss'],
    host: {
        'class': 'card-table'
    }
})
export class CardTableComponent extends CreatorExtendCore {

    @Input('data') data: any
    @ViewChild('card') card: ElementRef

    constructor(private _ref: ElementRef,
                public userService: UserService) {
        super(userService)
        this.host = this._ref
    }

    extendOnInit() {
        this._ref.nativeElement.setAttribute('id', this.model.id)
    }
}
