import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Rx'

@Injectable()
export class SubjectsService {

    private _$subject: Subject<any>[] = []
    private _sub: any[] = []


    $setSubject(key: string, val: any) {
        if (!this._$subject[key]) this._$subject[key] = new Subject<any>()
        this._$subject[key].next(val)

    }

    $subscribeSubject(key: string, res: any) {
        if (!this._$subject[key]) this._$subject[key] = new Subject<any>()
        this._sub.push(
            this._$subject[key].asObservable().subscribe(result => {
                if (res) res(result)
            })
        )
    }


    destroyAllSubject() {
        this._sub.forEach(sub => {
            sub.unsubscribe()
        })
    }

    pushSubscription(sub: any) {
        this._sub.push(sub)
    }


}
