import { FORM_PRODUCT_PRODOTTI } from './form-product-prodotti';
import { FORM_ACCOMMODATION_EDITOR } from "./form-accommodation-editor";
import { FORM_STORY_EDITOR } from "./form-story-editor";
import { FORM_BRANDS_EDITOR } from "./form-brands-editor";
import { FORM_STORY_BOGA } from "./form-story-boga";
import { FORM_NEWS } from "./form-news-editor";
import { FORM_CAREERS } from "./form-career-editor";
import { FORM_NAVIGATION_EDITOR } from "./form-navigation-editor";
import { VIP_CLIENT_FORM_EDITOR } from "./vip/vip-form-client";
import { FORM_USER_ACP } from "./vip/vip-form-user-acp";
import { FORM_USER_ACP_ROLE } from "./vip/vip-form-user-acp-role";
import { FORM_CLIENT_USERS } from "./vip/vip-form-client-users";
import { FORM_CLIENT_SERVICE } from "./vip/vip-form-client-service";
import { FORM_CLIENT_JCO_USER } from "./vip/vip-form-jco-user";
import { FORM_CLIENT_JCO_REGION } from "./vip/jco/vip-form-jco-region";
import { FORM_CLIENT_JCO_ROLE } from "./vip/jco/vip-form-jco-role";
import { FORM_CLIENT_JCO_PERMISSION } from "./vip/jco/vip-form-jco-permission";
import { FORM_GALLERY_SUGARSAND } from "./form-gallery-sugarsand-editor";
import { FORM_EVENT_SUGARSAND } from "./form-event-sugarsand-editor";
import { FORM_NEWS_BIMASENA } from "./form-news-bimasena-editor";
import { FORM_EVENT } from "./form-event-editor";
import { FORM_CATEGORY_BIMASENA } from "./form-category-bimasena-editor";
import { FORM_EVENT_BIMASENA } from "./form-event-bimasena-editor";
import { FORM_AFFILIATES_BIMASENA } from "./form-affiliates-bimasena-editor";
import { FORM_GALLERY_BIMASENA } from "./form-gallery-bimasena-editor";
import { FORM_STORES_EDITOR } from "./form-stores-editor";
import { FORM_PRODUCT_CATEGORY } from "./form-product-category-editor";
import { FORM_PRODUCT } from "./form-product-editor";
import { FORM_JCO_CAREERS } from "./form-jco-career-editor";
import { FORM_JCO_EVENT } from "./form-jco-event";
import { FORM_LEARN } from "./form-learn-editor";
import { FORM_BUNGALOW_EDITOR } from "./form-bungalows-tanjungsari-editor";
import { FORM_JCO_NEWS } from "./form-jco-news";
import { FORM_JCO_FLASH } from "./form-jco-flash";
import { FORM_JCO_PDF_MENU } from "./form-jco-pdf-menu";
import { FORM_CATEGORY } from "./form-category-editor";
import { FORM_OFFERS_EDITOR } from "./form-offers-tanjungsari-editor";

export const FORM_EDITOR = {
    accommodation: FORM_ACCOMMODATION_EDITOR,
    brands: FORM_BRANDS_EDITOR,

    "our-story": FORM_STORY_EDITOR,
    "story-boga": FORM_STORY_BOGA,
    "client-service": FORM_CLIENT_SERVICE,
    "jco-user": FORM_CLIENT_JCO_USER,
    "manage-region": FORM_CLIENT_JCO_REGION,
    "manage-role": FORM_CLIENT_JCO_ROLE,
    "manage-acp": FORM_CLIENT_JCO_PERMISSION,
    "news-bimasena": FORM_NEWS_BIMASENA,
    "event-bimasena": FORM_EVENT_BIMASENA,
    "event-sugarsand": FORM_EVENT_SUGARSAND,
    "category-bimasena": FORM_CATEGORY_BIMASENA,
    "affiliates-bimasena": FORM_AFFILIATES_BIMASENA,
    "gallery-bimasena": FORM_GALLERY_BIMASENA,
    "gallery-sugarsand": FORM_GALLERY_SUGARSAND,

    store: FORM_STORES_EDITOR,
    "product-category": FORM_PRODUCT_CATEGORY,
    product: FORM_PRODUCT,
    "jco-career": FORM_JCO_CAREERS,
    "jco-event": FORM_JCO_EVENT,
    "jco-news": FORM_JCO_NEWS,
    "flash-info": FORM_JCO_FLASH,
    "jco-pdf-menu": FORM_JCO_PDF_MENU,

    learn: FORM_LEARN,
    news: FORM_NEWS,
    event: FORM_EVENT,
    career: FORM_CAREERS,
    navigation: FORM_NAVIGATION_EDITOR,
    client: VIP_CLIENT_FORM_EDITOR,
    acp: FORM_USER_ACP,
    role: FORM_USER_ACP_ROLE,
    clientusers: FORM_CLIENT_USERS,

    post: FORM_BUNGALOW_EDITOR,
    category: FORM_CATEGORY,
    offerstanjungsari: FORM_OFFERS_EDITOR,
    productprodotti: FORM_PRODUCT_PRODOTTI
};
