import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {SocialMediaEditorComponent} from './social-media-editor.component'
import {FormCreatorModule} from '../../cores/creators/form/form.module'

@NgModule({
    imports: [
        CommonModule,
        FormCreatorModule
    ],
    declarations: [
        SocialMediaEditorComponent
    ],

    entryComponents: [
        SocialMediaEditorComponent
    ]
})

export class SocialMediaEditorModule {

}
