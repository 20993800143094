import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_BUNGALOW_EDITOR: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'post',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                {
                    title: 'Sub Title',
                    component: 'TextareaComponent',
                    model: 'subtitle:lang',
                    blockSize: 12
                },

                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },

                {
                    title: 'Booking Button Tile',
                    component: 'InputComponent',
                    model: 'link.title',
                    blockSize: 6
                },

                {
                    title: 'Booking Url',
                    component: 'InputComponent',
                    model: 'link.url',
                    blockSize: 6
                },

                {
                    title: 'The Stay Title',
                    component: 'InputComponent',
                    model: 'the_stay.title:lang',
                    blockSize: 6
                },

                {
                    title: 'The Stay Description',
                    component: 'TextEditorComponent',
                    model: 'the_stay.body:lang',
                    blockSize: 12
                },
            ]
        },

        projectCost: {
            fields: [
                {
                    component: 'MultipleAddComponent',
                    model: 'facilities',
                    title: 'Facilities',
                    blockSize: 12,
                    multipleAdd: {
                        blockSize: 6,
                        buttonTitle: 'item:lang'
                    },
                    multipleComponentToUse: [
                        {
                            title: 'Type',
                            component: 'InputComponent',
                            model: 'item:lang',
                            blockSize: 6,
                        },
                    ]
                },
                {
                    title: 'Facilities Featured',
                    component: 'ButtonFileComponent',
                    model: 'facilities_featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },


        gallery: {
            fields: [

                {
                    title: 'Header image',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
                {
                    title: 'Sliders',
                    component: 'ButtonFileComponent',
                    model: 'gallery',
                    blockSize: 12,
                    buttonFile: {
                        icon: 'icon-plus',
                        buttonType: 'image',
                        folderTarget: 'post',
                        multiple: true
                    }
                },

                {
                    title: 'Featured Thumbnail',
                    component: 'ButtonFileComponent',
                    model: 'featured_thumbnail',
                    blockSize: 12,
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    }
                },

                {
                    title: 'Featured Hover',
                    component: 'ButtonFileComponent',
                    model: 'featured_hover',
                    blockSize: 12,
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    }
                },
            ]
        },

        meta: {
            fields: [
                {
                    title: 'Meta Title',
                    component: 'InputComponent',
                    blockSize: 12,
                    model: 'meta.title:lang'
                },

                {
                    title: 'Meta Description',
                    component: 'InputComponent',
                    blockSize: 12,
                    model: 'meta.description:lang'
                },

                {
                    title: 'Featured Navigation',
                    component: 'ButtonFileComponent',
                    model: 'featured_nav',
                    blockSize: 12,
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    }
                },
            ]
        }
    }
}
