import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs/Rx'
import {DynamicManageNavigation} from '../cores/manage-navigation/dynamic-manage-navigation'

@Injectable()
export class ManageNavigationResolver implements Resolve<any> {
    constructor(private _dynamicNavigation: DynamicManageNavigation) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {


        return Observable.create(resolve => {
            const navigation = this._dynamicNavigation.getNavigation()



            /**
             * Pop navigation into route
             */
            if (navigation.length > 0) route.routeConfig.children.unshift(...navigation)
            /**
             * Send first route
             */
            resolve.next(navigation)
            resolve.complete()
        })
    }
}
