import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'

import * as $UID from 'uuid/v4'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'
import {EmailTemplateModel} from './email-template-model'
import {DomSanitizer} from '@angular/platform-browser'
import {FileUploadService} from '../../../../services/file-manager/file-upload.service'
import {environment} from '../../../../../environments/environment'
import {EditorDataService} from '../../../../services/editor/editor-data-service'
import {TimeoutWait} from '../../../../helpers/helper-async-timeout'
import {CrudService} from '../../../../services/crud-service'
import {LOOPING} from '../../../../helpers/helper-looping'
import {templateK01Render} from './template-layout/template-k01'


@Component({
    selector: 'vold-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.scss'],

})

export class EmailTemplateComponent extends CreatorExtendCore implements OnInit {

    inputId = $UID()
    css: any
    aceOptions: any = {
        printMargin: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true
    }


    socialMediaButton = {
        title: '',
        buttons: []
    }

    showSettingContainer: boolean

    body: any = {}

    emailTemplateOptions: EmailTemplateModel

    uploadingImage: boolean

    renderHTML: boolean

    @ViewChild('templating') templating: ElementRef
    @ViewChild('emailStyle') emailStyle: ElementRef

    constructor(private _ref: ElementRef,
                private _crudService: CrudService,
                private _domSanitizer: DomSanitizer,
                private _editorDataService: EditorDataService,
                private _fileUploadService: FileUploadService,
                public userService: UserService) {
        super(userService)
    }

    extendOnInit() {

        /**
         * Get the email template option
         */
        this.emailTemplateOptions = this.formDetail.emailTemplate

        /**
         * Get the css
         * @type {any}
         */
        this.css = this.getModelValue(this.emailTemplateOptions.styleModel)


        this.socialMediaButton.buttons = this.getModelValue(`body.${this.language}.network.buttons`) || []


    }

    extendAfterViewInit() {

        this.sub.push(
            this._editorDataService.$onSaveData.subscribe(() => {
                this.renderHTML = true
                this._getHtml()
            }),

            this._editorDataService.$getSingleDetail().subscribe(async () => {
                await TimeoutWait(200)
                this.renderHTML = false
            })
        )

    }


    onCssChange(cssValue) {

        this.setModelValue(cssValue, this.emailTemplateOptions.styleModel)
    }


    async uploadMedia(files) {

        this.uploadingImage = true

        const image = await this._fileUploadService.uploadFile({
            file: files,
            type: 'email-template'
        }, 'image')


        if (image.files.length > 0) this.setModelValue(environment.assets_uri + image.files[0].path, `body.${this.language}.banner`)

        this.uploadingImage = false


    }

    private _getHtml() {

        const html = this.templating.nativeElement.innerHTML

        const bodyClean = templateK01Render({
            content: this.getModelValue(`body.${this.language}.content`),
            socialNetworks : this.getModelValue(`body.${this.language}.network.buttons`),
            socialNetworksTitle : this.getModelValue(`body.${this.language}.network.title`),
            banner : this.getModelValue(`body.${this.language}.banner`),
            footer : this.getModelValue(`body.${this.language}.content_footer`),
        })

        // let body = html.replace(/<!--[\s\S]*?-->/g, '')
        //     .replace(/&lt;/g, '<')
        //     .replace(/&gt;/g, '>')
        //     .replace(/&nbsp;/g, '')
        //     .replace(/\n/g, '')
        //     .replace(/<\s*medium-editor[^>]*>(.*?)<\s*\/\s*medium-editor\s*>/g, '')
        //     .replace(/\r?\n|\r/g, '')
        //
        // const tempDiv = document.createElement('div')
        //
        // tempDiv.innerHTML = body
        //
        // let bodyClean = tempDiv.innerHTML
        //
        // this.checkingChild(tempDiv.children)
        //
        // console.log(tempDiv.children)
        //
        // console.log('body', tempDiv.innerHTML)
        //
        // // body = body.replace(/<\s*div[^>]*remove-on-render="true"[^>]*>(.*?)<\s*\/\s*div\s*>/g, '')
        //
        // bodyClean.replace(/\r?\n|\r/g, '')

        this.setModelValue(bodyClean, `body.${this.language}.html`)
        return html

    }


    checkingChild(child) {

        LOOPING(child, c => {

            if (c.hasAttribute('remove-on-render')) {
                c.parentNode.removeChild(c)

                if (c.children) {
                    this.checkingChild(c.children)
                }
            }
        })

    }

    addButton() {

        const body: any = this.getModelValue(`body.${this.language}.network`)

        const newButton = {
            url: '',
            title: '',
            image: ''
        }

        this.socialMediaButton.buttons.push(newButton)


        if (body && body.buttons) {
            body['buttons'] = this.socialMediaButton.buttons
        } else {
            this.setModelValue(this.socialMediaButton.buttons, `body.${this.language}.network.buttons`)
        }


    }

    onMediumEditorDataSet(data, model) {
        const newData = data.replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')

        this.onChangeMediumEditor(newData, model)
    }

    removeButton(index) {
        const body = this.getModelValue(`body.${this.language}.network`)
        this.socialMediaButton.buttons.splice(index, 1)
        body['buttons'] = this.socialMediaButton.buttons
    }
}
