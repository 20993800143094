import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ImageEditorComponent} from './image-editor.component'
import {PipesModule} from '../../../../../pipes/pipes.module'
import {FormsModule} from '@angular/forms'

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        FormsModule
    ],
    entryComponents: [
        ImageEditorComponent
    ],
    declarations: [
        ImageEditorComponent
    ],
    providers: [
        { provide: "window", useValue: window}
    ]
})
export class ImageEditorModule {
}
