export const defaultCardModel = {
    id: 'id',
    card: 'CardSimpleComponent',
    blockSize: 3,
    cardClickedFn: 'editor',
    content: {
        heading: 'title:lang',
        text_one: 'status',
        text_two: '',
        summary: 'meta.description:lang'
    },

    // nav popup option
    navOptions: [
        {
            id: 'edit',
            title: 'Edit Page',
            icon: 'edit-pencil-right',
            callbackFn: 'editor'
        },

        {
            id: 'delete',
            icon: 'trash',
            title: 'Delete Page',
            callbackFn: 'delete',
            isDeleteAction: true
        }
    ]
}
