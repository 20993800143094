export class Vaildator {


    noSpace(content) {

        let hasSpace: any = false
        if (content.match(/\s/)) {
            console.log('Has space')
            hasSpace = {
                invalid: true,
                message: 'Space not allowed!'
            }
        }

        return hasSpace

    }


    email(content) {
        let isInvalid: any = false
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const validate = re.test(content)
        if (!validate) {
            isInvalid = {
                invalid: true,
                message: 'Please use a valid email'
            }

        }

        return isInvalid

    }

}
