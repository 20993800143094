import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {CalendarService} from '../../services/calendar/calendar.service'
import {CalendarConfigModel} from './calendar-config-model'
import {ModalPopupService} from '../../cores/modal/service/modal.service'
import {LOOPING_ASYNC} from '../../helpers/helper-looping'

@Component({
    selector: 'vold-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {

    datesData: any = {}
    weeks: string[] = []
    calendarConfig: CalendarConfigModel = new CalendarConfigModel()
    dataSelection: any = []
    currentSelectedItem: string
    showListingData: boolean
    currentDateModel: any
    @ViewChild('calendar') calendar: ElementRef

    @Output('onDateClick') onDateClick: EventEmitter<any> = new EventEmitter<any>()

    constructor(private CalendarService: CalendarService,
                private _modalService: ModalPopupService) {
    }

    async ngOnInit() {

        /**
         * Because of date formatting DD//MM/YYY, cuz error when we convert back into date
         */
        const splittingDate = (this.currentDateModel ? this.currentDateModel.split('/') : ['yyyy',"MM","dd"])

        /**
         * Because month is start from 0
         * @type {Date}
         */
        const currentDate: any = new Date(+splittingDate[2], (+splittingDate[1] - 1), +splittingDate[0])


        this.weeks.push(...this.CalendarService.days)
        this.datesData = await this.CalendarService.createDates(currentDate.getFullYear(), currentDate.getMonth())

        LOOPING_ASYNC(this.datesData.dates, date => {
            date['is_passed'] = false


            if (Date.parse(currentDate) === Date.parse(date.dateStamp)) {
                date['is_selected'] = true
            }

            /**
             * If has start date and end date option
             */
            if (this.CalendarService.startDateSelected &&
                this.calendarConfig && this.calendarConfig.endDate) {
                const selectedDate = Date.parse(this.CalendarService.startDateSelected) || Date.parse(currentDate)
                const dateDate = Date.parse(date.dateStamp)

                if (dateDate <= selectedDate) {
                    date.is_passed = true
                }
            }

        })


        console.log('this.datesData', this.datesData.dates)


        try {
            if (this.calendarConfig.blockSize)
                this.calendar.nativeElement.classList.add(`block-${this.calendarConfig.blockSize}`)
            else
                this.calendar.nativeElement.classList.add(`block-12`)
        } catch (e) {
            this.calendar.nativeElement.classList.add(`block-12`)
        }
    }

    async ngAfterViewInit() {


    }


    async prevMonth() {
        this.datesData = await this.CalendarService.getPrevMontDates()
        this.createDateForStartEndData()
    }

    async nextMonth() {
        this.datesData = await this.CalendarService.getNextMontDates()
        this.createDateForStartEndData()
    }


    viewMonth() {

        this.showListingData = !this.showListingData

        this.currentSelectedItem = 'm'
        this.dataSelection = this.CalendarService.months
    }

    viewYear() {

        this.showListingData = !this.showListingData

        // if (!this.showListingData) return

        this.currentSelectedItem = 'y'
        this.dataSelection = []
        const currentYear = new Date()


        for (let i = 0; i < 20; i++) {
            this.dataSelection.push((currentYear.getFullYear() - 10) + i)
        }


    }

    async changeYM(data, i) {

        if (this.currentSelectedItem === 'm') {
            this.datesData = await this.CalendarService.createDates(this.datesData.year, i)
            this.createDateForStartEndData()
        }
        else {
            this.datesData = await this.CalendarService.createDates(data)
            this.createDateForStartEndData()
        }

        this.showListingData = false
    }

    dateClick(date) {

        this.onDateClick.emit(date)

    }

    createDateForStartEndData() {

        console.log('this.CalendarService.startDateSelected', this.CalendarService.startDateSelected)

        LOOPING_ASYNC(this.datesData.dates, date => {
            date['is_passed'] = false


            /**
             * If has start date and end date option
             */
            if (this.CalendarService.startDateSelected &&
                this.calendarConfig && this.calendarConfig.endDate) {
                const selectedDate = Date.parse(this.CalendarService.startDateSelected)
                const dateDate = Date.parse(date.dateStamp)

                if (dateDate <= selectedDate) {
                    date.is_passed = true
                }
            }

        })

        console.log('this.datesData.dates', this.datesData.dates)
    }

    async closeCalendar() {
        await this._modalService.closeModal()
    }

}
