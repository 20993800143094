import {Injectable} from '@angular/core'
import {UserService} from '../../services/user/user-service'
import {TABLE_SETTINGS} from '../../cards/card-settings-listing'
import {LayoutCardViewComponent} from '../../components/dynamic-layouts-view/card-view/layout-card-view.component'
import {generatePostTypeInput} from '../../helpers/functions/functions'
import {LayoutCardTableViewComponent} from '../../components/dynamic-layouts-view/card-table/layout-card-table-view.component'
import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

@Injectable()
export class DynamicManageNavigation {

    private _navigation: any[] = []

    constructor(private _userService: UserService) {
    }

    getNavigation() {
        this._navigation = []

        this._navigationChecker()
        return this._navigation

    }

    private _navigationChecker() {

        if (!this._userService.clientDashboardConfig.dashboard_navigation) return

        this._userService.clientDashboardConfig.dashboard_navigation.map(route => {

            /**
             * Set layout of display list
             */
            if (route.data.list_layout === 'card') {
                route.component = LayoutCardViewComponent
            } else {
                route.component = LayoutCardTableViewComponent
            }


            if (route.data.cardDetail && !route.data.tableSetting) {
                if (!route.data.cardDetail['apiModel']) route.data.cardDetail['apiModel'] = []


                if (!route.disabled_create) {

                    /**
                     * If has no field for create new data
                     * set default one
                     */
                    if (!route.data.cardDetail.apiModel.createNewFields) {
                        const postType: FormModelSchema = generatePostTypeInput(route.data.cardDetail.apiModel.post_type)
                        route.data.cardDetail.apiModel.createNewFields = [
                            {
                                title: 'Name',
                                model: 'title:lang',
                                required: true,
                                inputComponent: {
                                    min: 4
                                },
                                component: 'InputComponent',
                            },
                            {
                                model: 'status',
                                type: 'hidden',
                                value: 'draft',
                                component: 'InputComponent',
                            },
                            postType
                        ]
                    }
                }

            }
            /**
             * If table detail settings provide
             */
            if (route.data.tableSetting && !route.data.live_editor) route.data.cardDetail = TABLE_SETTINGS[route.data.tableSetting]
            this._navigation.push(route)
        })

    }
}
