import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HtmlToPlainPipe} from './html-to-plain.pipe'
import {BackgroundImgSafePipe} from './background-img-safe.pipe'
import {ImageSourcePipe} from './image-source.pipe'
import {SafePipeUrl} from './safe-pipe'
import {SafeUrlSourcePipe} from './safe-url-source.pipe'
import {LanguageDetectionPipe} from './language-detection.pipe'
import {SanitizeHtmlPipe} from './html-sanitizer.pipe'
import {GaPipe} from './ga-pipe'
import {ToFixed} from './toFixed'

@NgModule({
    imports: [
        CommonModule
    ],

    exports: [
        HtmlToPlainPipe,
        BackgroundImgSafePipe,
        ImageSourcePipe,
        SafePipeUrl,
        SafeUrlSourcePipe,
        LanguageDetectionPipe,
        SanitizeHtmlPipe,
        GaPipe,
        ToFixed
    ],

    declarations: [
        HtmlToPlainPipe,
        BackgroundImgSafePipe,
        BackgroundImgSafePipe,
        ImageSourcePipe,
        SafePipeUrl,
        LanguageDetectionPipe,
        SafeUrlSourcePipe,
        SanitizeHtmlPipe,
        GaPipe,
        ToFixed
    ]
})

export class PipesModule {

}
