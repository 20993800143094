import {Component, ElementRef, OnInit, ViewContainerRef} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {ModalPopupService} from '../../../modal/service/modal.service'
import {CalendarComponent} from '../../../../components/calendar/calendar.component'
import {DatePipe} from '@angular/common'
import {UserService} from '../../../../services/user/user-service'
import {CalendarConfigModel} from '../../../../components/calendar/calendar-config-model'
import {CalendarService} from '../../../../services/calendar/calendar.service'
import * as moment from 'moment'

@Component({
    selector: 'vold-input-calendar',
    templateUrl: './input-calendar.component.html',
    styleUrls: ['./input-calendar.component.scss'],
    providers: [
        DatePipe
    ]
})
export class InputCalendarComponent extends CreatorExtendCore implements OnInit {

    private _calendarConfig: CalendarConfigModel = new CalendarConfigModel()

    constructor(public ref: ElementRef,
                private _vcr: ViewContainerRef,
                private _datePipe: DatePipe,
                private _modalService: ModalPopupService,
                private _calendarService: CalendarService,
                public userService: UserService) {
        super(userService, ref)
    }

    extendAfterViewInit() {
        this.sub.push(
            this._calendarService.$listenDateReset().subscribe(() => {
                this.setModelValue('')
                this.onDataChange.emit(this.model)
                this.renderModelData()
            })
        )
    }

    extendOnInit() {
        this._calendarConfig = this.formDetail.inputCalendar

        /**
         * Set the start date
         */
        if (this._calendarConfig && this._calendarConfig.startDate) {
            /**
             * Because of date formatting DD//MM/YYY, cuz error when we convert back into date
             */
            const splittingDate = this.getModelValue().split('/')

            /**
             * Because month is start from 0
             * @type {Date}
             */
            const currentDate: any = new Date(+splittingDate[2], (+splittingDate[1] - 1), +splittingDate[0])
            this._calendarService.startDateSelected = new Date(currentDate)
        }
    }


    async showCalendar() {

        const calendar = await this._modalService.createModal(this._vcr, CalendarComponent)
        calendar.calendarConfig = this.formDetail.inputCalendar
        calendar.currentDateModel = this.getModelValue()

        this.sub.push(
            calendar.onDateClick.subscribe(date => {

                let dateFormat = 'dd/MM/yyyy'

                if (this._calendarConfig && this._calendarConfig.startDate)
                    this._calendarService.startDateSelected = date.dateStamp

                if (this.formDetail.inputCalendar) dateFormat = this.formDetail.inputCalendar.dateFormat


                if (this.formDetail.inputCalendar && this.formDetail.inputCalendar.formatDate) {
                    this.setModelValue(moment(date.dateStamp).format('YYYY-MM-DD'))
                } else {
                    this.setModelValue(date.dateStamp)
                }

                this.renderModelData()
                this._modalService.closeModal()
                this.onDataChange.emit(this.model)
            })
        )
    }

    extendOnDestroy() {

        if (this._calendarService.startDateSelected) this._calendarService.startDateSelected = null

    }
}
