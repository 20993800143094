import {CardApiSchema} from './card-model'
import {SlideToggleModel} from '../../cores/creators/form-components/slide-toggle/model/slide-toggle.model'
import {ButtonSubmitModel} from '../../cores/creators/form-components/button-submit/button-submit-model'
import {MultipleAddModel} from '../../cores/creators/form-components/multiple/multiple-add-model'
import {ButtonFileModel} from '../../cores/creators/form-components/button-file/button-file-model'
import {LooperModelSchema} from '../../cores/creators/form-components/looper/looper-model'
import {GoogleMapModel} from '../../cores/creators/form-components/google-map/google-map-model'
import {ButtonFileAvatarModel} from '../../cores/creators/form-components/button-file-avatar/button-file-avatar-model'
import {CalendarConfigModel} from '../../components/calendar/calendar-config-model'
import {CardTableModel} from '../../cores/creators/card-component/card-table/card-table-model'
import {SelectOptionsModelSchema} from '../../cores/creators/form-components/select-option/select-option.model'
import {TextEditorModel} from '../../cores/creators/form-components/text-editor/text-editor-model'
import {EmailTemplateModel} from '../../cores/creators/form-components/email-template/email-template-model'
import {TextAreaModel} from '../../cores/creators/form-components/text-area/textarea-model'
import {TextDisplayModel} from '../../cores/creators/form-components/text-display/text-display-model'
import {InputModel} from '../../cores/creators/form-components/input/input.model'
import {CheckBoxComponentModel} from '../../cores/creators/form-components/check-box-component/check-box-component.model'

export class FormModelSchema {
    /**
     * Set id of input
     */
    id?: string

    readonly?: boolean

    /**
     * Model to be assign
     */
    model?: any | string

    modelAlias?: any


    /**
     * Path api model
     * @value string or object
     */
    pathModel?: string | any
    keyStateId?: string

    /**
     * Base route path
     */
    baseRoute?: string

    userData?: boolean

    /**
     * Can activate only user with specific role
     * @Deprecated
     */
    canActive?: string[]

    callBackOnInit?: any
    callBackAfterViewInit?: any


    userRole?: string[]

    /**
     * If any model with condition has no value
     * show this component
     */
    canShow?: CanShowComponent


    /**
     * This value should be return boolean
     */
    condition?: IfElseConditionModel
    conditionShow?: IfElseConditionModel

    /**
     * Title of input
     */
    title?: string

    note?: string

    /**
     * Related to model Model
     */
    related?: string


    /**
     * If related value is boolean then use this property to set the value
     */
    setModelRelated?: string
    setModelRelatedValue?: any[]

    /**
     * Component form to be assign
     */
    component?: any

    required?: boolean
    modelChain?: string


    /**
     * If MultipleAddComponent
     * set component do you want to use
     */
    multipleComponentToUse?: FormModelSchema[]


    /**
     * Set value of input
     */
    value?: any

    /**
     * Set default value
     */
    defaultValue?: any


    /**
     * Set options of select options
     */
    options?: SelectOptionsModelSchema

    /**
     * @deprecated
     * @type {{}}
     */
    source?: any = {}


    /**
     * Validation
     * To validate result toggle the button
     * Only use within slidetoggle component
     */
    validation?: ValidatorData

    validationRegex?: any

    /**
     * Define style of the class element
     * Use coma to define multiple class
     */
    styleClass?: string


    /**
     * Disabled or enabled form
     */
    disabled?: boolean


    /**
     * Set type of input type
     * Default text
     * @type {string}
     */
    type?: string = 'text'


    blockSize?: number = 12

    multipleLanguage?: boolean

    group?: FormGroupModelSchema


    /**
     * True, will return the first data
     */
    singleData?: boolean

    formData?: CardApiSchema

    /**
     * Components models
     */
    looper?: LooperModelSchema
    slideToggle?: SlideToggleModel
    buttonSubmit?: ButtonSubmitModel
    buttonFile?: ButtonFileModel
    multipleAdd?: MultipleAddModel
    googleMap?: GoogleMapModel
    buttonFileAvatar?: ButtonFileAvatarModel
    inputCalendar?: CalendarConfigModel
    cardTable?: CardTableModel
    textEditor?: TextEditorModel
    emailTemplate?: EmailTemplateModel
    textArea?: TextAreaModel
    textDisplay?: TextDisplayModel
    inputComponent?: InputModel
    checkBox?: CheckBoxComponentModel
}


export class ValidatorData {

    value?: string
    result?: boolean
    password?: PasswordValidation
    type?: string
    minLengthText?: number
}


export class PasswordValidation {
    /**
     * Minimum char
     */
    minLength ?: number
    reCheckPassword?: string
}


export class FormGroupModelSchema {
    [key: string]: FormGroupDataModelSchema

}


export class FormGroupDataModelSchema {
    canShow?: CanShowComponent
    title ?: string
    note ?: string
    /**
     * This should be a boolean action
     * @value = model name of action to be triggered
     */
    canEnabled?: string
    sideFields?: FormModelSchema[]
    fields?: FormModelSchema[]

    /**
     * Use component instead of fields
     */
    looper?: LooperModelSchema
}


export class FormObjectModel {
    [key: string]: any
}


export class IfElseConditionModel {

    modelCondition?: string
    modelValue?: string
    modelDefaultValue?: string
    conditionValue?: boolean
    condition?: string

}


export class CanShowComponent {
    value?: any
    modelChecker?: string
}
