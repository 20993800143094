import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MediumEditorDirective} from './medium-editor.directive'
import {SliderDirective} from './slider.directive'
import {InfiniteScrollDirective} from './infinite-scroll.directive'
import {ScrollListenerDirective} from './scroll-listener.directive'
import {CardStaggerAnimationDirective} from './card-stagger-animation.directive'
import {DragDropDirective} from './drag-drop.directive'
import {SquareDirective} from './square.directive'
import {ClickOutsideEventDirective} from './click-outside-event.directive'
import {SimpleDropdownDirective} from './simple-dropdown.directive'

@NgModule({
    imports: [
        CommonModule
    ],

    exports: [
        MediumEditorDirective,
        SliderDirective,
        InfiniteScrollDirective,
        ScrollListenerDirective,
        CardStaggerAnimationDirective,
        DragDropDirective,
        SquareDirective,
        ClickOutsideEventDirective,
        SimpleDropdownDirective
    ],

    declarations: [
        MediumEditorDirective,
        SliderDirective,
        InfiniteScrollDirective,
        ScrollListenerDirective,
        CardStaggerAnimationDirective,
        DragDropDirective,
        SquareDirective,
        ClickOutsideEventDirective,
        SimpleDropdownDirective
    ]
})

export class DirectivesBundleModule {

}
