import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output} from '@angular/core'

@Directive({
    selector: '[voldScrollListener]'
})
export class ScrollListenerDirective implements AfterViewInit {

    @Input('pageDetail') pageDetail: any

    @Output('onDistanceChange') onDistanceChange: EventEmitter<any> = new EventEmitter<any>()

    constructor(private _el: ElementRef,
                private _zone: NgZone) {

    }


    ngAfterViewInit() {

        this._zone.run(() => {
            this._el.nativeElement.addEventListener('scroll', async (event) => {
                this.onDistanceChange.emit(event)

            })
        })


    }
}
