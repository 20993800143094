import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'

@Pipe({name: 'GAnalytics'})

export class GaPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(str) {

        let ga = str.split(':')
        ga = ga.pop().split(/(?=[A-Z])/)
        ga = ga.join(' ')
        ga = ga.charAt(0).toUpperCase() + ga.substr(1)
        return ga
    }
}
