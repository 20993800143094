import {CardModelSchema} from '../models/dynamic-compopent/card-model'

export const CARD_FILE_MANAGER: CardModelSchema = {
    id: 'id',
    content: {
        text1: 'file_name'
    },
    card: 'SingleImageComponent',
    featured: 'thumbnails.medium' || 'path',
    blockSize: 3
}
