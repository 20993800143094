import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core'
import {CARD_COMPONENT} from '../card-component/card-components.const'
import {CardModelSchema} from '../../../models/dynamic-compopent/card-model'
import {CreateCardComponentService} from '../../../services/create/create-card-component.service'
import {ElasticDataService} from '../../../services/elastic/elastic-data-service'

@Component({
    selector: 'vold-card-creator',
    templateUrl: './create-card.component.html',
    styleUrls: ['./create-card.component.scss']
})
export class CreateCardComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input('data') data: any

    @Input('model') model: any

    @Input('cardDataArc') cardDataArc: CardModelSchema

    @ViewChild('componentInjector', {read: ViewContainerRef}) componentInjector: ViewContainerRef
    @ViewChild('mainCardComponent') mainCardComponent: ElementRef


    private _sub: any = []

    private _cardChooser = CARD_COMPONENT

    private _cardsComponents: any[] = []

    constructor(private _componentResolver: ComponentFactoryResolver,
                private _elasticService: ElasticDataService,
                private _createCard: CreateCardComponentService) {
    }

    ngOnInit() {
        /**
         * Create card at first time
         */
        this._createCards()
    }

    ngAfterViewInit() {

        /**
         * Listen card data changed
         * if changed create new card and append it into template
         */
        this._sub.push(
            this._createCard.$getUpdatedCardData().subscribe(() => {
                this._createCards()
            }),

            this._createCard.$getNewCardCreatedData().subscribe((data) => {
                this._createSingleCard(data)
            }),
        )

    }

    private _createSingleCard(data, index?: number, push?: boolean) {
        const cardDetail: any = this._createCard.cardDetail.model

        /**
         * New Update
         */
        const component = (cardDetail ? this._cardChooser[cardDetail.card] : null)


        let factory: any = null
        let ref: any = null

        if (!component) return


        try {
            factory = this._componentResolver.resolveComponentFactory(component)
            if (!push) ref = this.componentInjector.createComponent(factory, index)
            else ref = this.componentInjector.createComponent(factory)
            const callerComponentName = ref.injector.view.viewContainerParent


            ref.instance.model = data
            ref.instance.cardIndex = index

            /**
             * New Update
             * @type {CardModelSchema}
             */
            ref.instance.cardDetail = cardDetail
            ref.instance.callerClass = callerComponentName.parent.component

            this._cardsComponents.push(ref)

        } catch (err) {
            console.log('Err', err)
        }
    }


    private _createCards() {
        this._createCard.cardData.forEach((item, index) => {
            this._createSingleCard(item, index, true)
        })
    }

    deleteItem(index: number) {
        this._cardsComponents[index].destroy()
    }


    ngOnDestroy() {
        this._cardsComponents.forEach((comp: any) => {
            comp.destroy()
        })
    }
}
