import {CardLayoutSchema} from '../models/dynamic-compopent/card-model'

export const CARD_EVENT: CardLayoutSchema = {
    apiModel: {
        path: 'event',
        params: {
            where: '{"meta.type": "event"}'
        },
        limit: 20,
        createNewFields: [
            {
                title: 'Title',
                model: 'title:lang',
                required: true,
                component: 'InputComponent',
            },
            {
                model: 'status',
                type: 'hidden',
                value: 'draft',
                component: 'InputComponent',
            },
            {
                model: 'meta.type',
                type: 'hidden',
                value: 'event',
                component: 'InputComponent',
            },
        ]
    },

    editor: {
        prefixPath: 'p/manage/jco-event/editor'
    },


    model: {
        id: 'id',
        cardTable: {
            header: [
                'Report',
                'Created At'
            ],
            filterByHeader: [
                'title',
                'created_at'
            ],
            data: [
                'title:lang',
                'created_at,%d'
            ],
            colSize: [6, 6],
        }
    }
}
