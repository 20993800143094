import {Injectable} from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {CrudService} from '../crud-service'
import {EditorService} from '../editor/editor-service'
import {UserService} from '../user/user-service'
import {SubjectsService} from '../subjects.service'

@Injectable()
export class PostTypeService {

    constructor(private _activatedRoute: ActivatedRoute,
                private _editorService: EditorService,
                private _subjectService: SubjectsService,
                private _userService: UserService,
                private _crudService: CrudService) {

    }


    async getParams() {

        return new Promise(resolve => {
            this._activatedRoute.queryParams.subscribe(params => {
                if (params.post_type) {
                    resolve(`postType=${params.post_type}`)
                } else {
                    resolve(null)
                }
            })
        })
    }

    async updatePostDetail(data, id) {

        const postDetail = this._editorService.data.singleData
        if (!postDetail.components) postDetail.component = {}
        postDetail.components[this._userService.language] = data
        const updatedData: any = await this._crudService.PUT('post', id, postDetail)
        if (!updatedData.length) return this._subjectService.$setSubject('DATA_UPDATED', false)
        this._subjectService.$setSubject('DATA_UPDATED', true)
        this._editorService.data.singleData = updatedData[0]
    }


}
