import {CardLayoutSchema} from '../models/dynamic-compopent/card-model'

export const CARD_GALLERY: CardLayoutSchema = {
    model: {
        blockSize: 3,
        featured: 'path',
        card: 'SingleImageComponent',
        singleImage: {
            useBackground: true,
        }
    }
}
