import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FormComponent} from './form.component'
import {FormComponentsCreatorModule} from '../form-components/form-components.module'

@NgModule({
    imports: [
        CommonModule,
        FormComponentsCreatorModule
    ],
    exports: [
        FormComponent
    ],
    declarations: [
        FormComponent
    ]
})
export class FormCreatorModule {
}
