import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_CATEGORY: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'category',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },
                {
                    title: 'Description',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        }
    }
}
