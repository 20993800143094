export const ADD_MORE_SECTION = 'ADD_MORE_SECTION'
export const INSERT_SECTION = 'INSERT_SECTION'
export const DUPLICATE_SECTION = 'DUPLICATE_SECTION'
export const RE_INIT_SCRIPT = 'RE_INIT_SCRIPT'
export const DELETE_SECTION = 'DELETE_SECTION'
export const REQ_REGISTERED_SECTIONS = 'REQ_REGISTERED_SECTIONS'
export const REQ_BUILDER_FORM_DATA = 'REQ_BUILDER_FORM_DATA'
export const GET_BUILDER_FORM_DATA = 'GET_BUILDER_FORM_DATA'
export const EDITING_FOOTER = 'EDITING_FOOTER'
export const DONE_EDITING_FOOTER = 'DONE_EDITING_FOOTER'
export const GET_REGISTERED_SECTIONS = 'GET_REGISTERED_SECTIONS'
export const SORT_WEBSITE_SECTION = 'SORT_WEBSITE_SECTION'

export const RELOAD_PB = 'RELOAD_PB'
export const SAVE_PB_DATA = 'SAVE_PB_DATA'
export const ACCESS_FILE_MANAGER = 'ACCESS_FILE_MANAGER'
export const SELECTED_FILE = 'SELECTED_FILE'
export const SELECTED_FILES = 'SELECTED_FILES'
export const BUILDER_READY = 'BUILDER_READY'
export const BUILDER_DATA = 'BUILDER_DATA'
export const REQ_COMPONENTS = 'GET_COMPONENTS'
export const GET_REGISTERED_COMPONENTS = 'GET_REGISTERED_COMPONENTS'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const REQ_DATA = 'REQ_PB_DATA'
export const UPDATE_WEB_CONFIG = 'UPDATE_WEB_CONFIG'
export const EDIT_SOCIAL_NETWORK = 'EDIT_SOCIAL_NETWORK'


export const REQ_UPDATE_FOOTER = 'REQ_UPDATE_FOOTER'
export const REQ_MANAGE_FOOTER_NAVIGATION = 'REQ_MANAGE_FOOTER_NAV'

export const SEND_FOOTER_DATA = 'SEND_FOOTER_DATA'
export const SEND_NAVIGATION = 'SEND_NAVIGATION'

export const CREATE_POST = 'CREATE_POST'

export const EDIT_POST = 'EDIT_POST'

export const NAVIGATE_BACK = 'NAVIGATE_BACK'
export const CHECK_CONTENT = 'CHECK_CONTENT'


export const SALT_KEY = '*=_H1Q*`41KZ9?-Vp|l<!-]|:w+PI+xh$cuW3w#DZ-2X@CeV.vMXh^]qB4RK1Cw+'
