import {
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output
} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {ButtonFileModel} from './button-file-model'
import {FileUploadService} from '../../../../services/file-manager/file-upload.service'
import {CardModelSchema} from '../../../../models/dynamic-compopent/card-model'
import {CARD_GALLERY} from '../../../../cards/card-gallery'
import {NotifDialogService} from '../../../../services/notif-service'
import {UserService} from '../../../../services/user/user-service'
import {ModalPopupService} from '../../../modal/service/modal.service'
import {FileManagerComponent} from '../../../file-manager/file-manager.component'
import {Subscription} from 'rxjs/Rx'

@Component({
    selector: 'vold-button-file',
    templateUrl: './button-file.component.html',
    styleUrls: ['./button-file.component.scss'],

})

export class ButtonFileComponent extends CreatorExtendCore implements OnInit {


    @Output() onSuccessUpload: EventEmitter<any> = new EventEmitter<any>()

    @Input() buttonText: string = 'Upload'
    @Input() buttonType: string = 'image'
    @Input() folderTarget: string = 'global'
    @Input() uploadMultiple: boolean = false
    buttonDetail: ButtonFileModel
    images: any = []

    /**
     * For single image upload
     */
    image: any
    galleryDetail: CardModelSchema = CARD_GALLERY.model
    uploadInProgress: boolean
    nestedObjectModel: any
    fileManagerSubscriber: Subscription

    constructor(public ref: ElementRef,
                private _notifDialog: NotifDialogService,
                private _fileUpload: FileUploadService,
                private _modalService: ModalPopupService,
                private _factoryResolver: ComponentFactoryResolver,
                private _applicationRef: ApplicationRef,
                private _injector: Injector,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {

        if (this.formDetail.model && this.formDetail.model.indexOf('.') > -1) this.nestedObjectModel = true
        if (this.modelAlias[this.inputId]) this.buttonText = 'Update'


        if (this.formDetail.buttonFile) {
            this.buttonDetail = this.formDetail.buttonFile
            this.buttonType = this.buttonDetail.buttonType
            this.folderTarget = this.buttonDetail.folderTarget
            this.uploadMultiple = this.buttonDetail.multiple

            // console.log('buttonType : ', this.buttonType)
        }

        this.getImage()
    }


    async openFileManager() {
        const fileManager = await this._modalService.injectAtRoot(FileManagerComponent, this._factoryResolver, this._applicationRef, this._injector)
        fileManager.component.instance.accessFromButton = true
        fileManager.component.instance.multipleSelection = this.uploadMultiple
        this.fileManagerSubscriber = fileManager.component.instance.getSelectedFile().subscribe(res => {
            this.setInputFile(res)
            this._modalService.detachView(fileManager.container)
            // fileManager.container.application.detachView(fileManager.container.compRef.hostView)
            // fileManager.container.compRef.destroy()
        })
    }


    setInputFile(image) {
        // this.image = image
        //
        // /**
        //  * Update model
        //  */
        // if (this.nestedObjectModel) this.setModelValue(image)
        // else {
        //     if (this.model) this.model[this.formDetail.model] = image
        // }
        // this.onDataChange.emit(this.model)
        //
        // /**
        //  * Trigger action when image uploaded
        //  */
        // this.onSuccessUpload.emit(image)
        // this.renderModelData()
        //
        //
        this.onFileChange(image)
    }


    async onFileChange(files: any) {


        if (this.buttonDetail && this.uploadMultiple) {

            let newImage = []

            if (!this.formDetail.model) return
            if (!this.model[this.formDetail.model]) this.model[this.formDetail.model] = []

            this.model[this.formDetail.model].push(...files)
            this.images.push(...files)
            this.onDataChange.emit(this.model)
            this.renderModelData()


        } else {
            /**
             * Update local
             */
            this.image = files

            /**
             * Update model
             */
            if (this.nestedObjectModel) this.setModelValue(files)
            else {
                if (this.model) this.model[this.formDetail.model] = files
            }
            this.onDataChange.emit(this.model)

            /**
             * Trigger action when image uploaded
             */
            this.onSuccessUpload.emit(files)
            this.renderModelData()
        }

        this.uploadInProgress = false

    }

    getImage() {

        if (!this.model) return
        let image = this.model[this.formDetail.model]

        if (this.buttonDetail && this.buttonDetail.multiple)
            this.images.push(...image)
        else {

            if (this.nestedObjectModel) {
                this.image = this.getModelValue()
            } else {
                this.image = image
            }

        }
    }


    private async _updateFile(files) {

        this.uploadInProgress = true


        const isSecure = await this._fileUpload.imageRestricted(files, this.buttonType)
        if (!isSecure) return this.uploadInProgress = false


        const updateFile: any = await this._fileUpload.uploadFile({
            type: this.model.sub,
            file: files
        }, this.buttonType)


        delete updateFile.files[0]['alt']
        delete updateFile.files[0]['sub']
        delete updateFile.files[0]['description']

        this.model = updateFile.files[0]

        this.onDataChange.emit(this.model)

        this.uploadInProgress = false

    }

    deleteImage(data, index) {

        if (this.buttonDetail.multiple) {
            this.images.splice(index, 1)
            this.onDataChange.emit(this.model)
            this.model[this.formDetail.model].splice(index, 1)
        } else {
            this.image = ''
            this.model[this.formDetail.model] = ''
            this.onDataChange.emit(this.model)
        }

        this._notifDialog.showDialog({
            type: 'success',
            message: 'Successfully deleted file!'
        })

        this.renderModelData()
    }

    extendOnDestroy() {

        if (this.fileManagerSubscriber) this.fileManagerSubscriber.unsubscribe()
    }
}
