import {AfterViewInit, Component, Inject, OnInit, ViewContainerRef} from '@angular/core'
import {ModalPopupService} from './cores/modal/service/modal.service'
import {NavigationEnd, Router} from '@angular/router'
import {SubjectsService} from './services/subjects.service'
import {DOCUMENT} from '@angular/common'
import {DomActionService} from './services/dom/dom-action.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    constructor(private _vcr: ViewContainerRef,
                private _router: Router,
                private _subjectService: SubjectsService,
                @Inject(DOCUMENT) private _document: Document,
                private _domService: DomActionService,
                private _modalService: ModalPopupService) {
    }

    ngOnInit() {

        this._router.events.subscribe((event: any) => {
            this._catchRouteEvent(event)
        })

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBaT4q7x_79V1be2iJAmsTCs30admnDEgI'
        document.body.appendChild(script)
    }


    private _catchRouteEvent(event: any) {
        if (event instanceof NavigationEnd) {
            this._subjectService.destroyAllSubject()
        }
    }

    ngAfterViewInit() {
        this._document.addEventListener('keyup', (ev) => {
            this._domService.$setEscHint(ev.keyCode === 27)
        })
        this._modalService.appComponentRef = this._vcr




    }
}
