export function generateParamModelQuery(apiDetail: any, limitData: number) {

    let query = {
        limit: limitData || 999
    }

    if (apiDetail.post_type) {
        query['type'] = apiDetail.post_type
    }

    if (apiDetail.sort) {
        query['sort'] = apiDetail.sort
    } else {
        query['sort'] = 'created_at DESC'
    }

    return query
}


export function generatePostTypeInput(postType) {

    if (!postType) return {}
    return {
        model: 'type',
        type: 'hidden',
        value: postType,
        component: 'InputComponent',
    }


}
