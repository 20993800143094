import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'

@Component({
    selector: 'vold-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent extends CreatorExtendCore implements OnInit {

    hour: number[] = []

    data: any = {
        hour: '01',
        minute: '00',
        second: '00'
    }

    constructor(public userService: UserService, public el: ElementRef) {
        super(userService, el)
    }

    extendOnInit() {
        this._createHour()
        let modelData = this.getModelValue()
        if (modelData) {
            modelData = modelData.split(':')
            this.data.hour = modelData[0]
            this.data.minute = modelData[1]
            this.data.second = modelData[2]
        }


    }

    private _createHour() {
        for (let i = 1; i < 25; i++) {
            this.hour.push(i)
        }
    }


    checkNumber(event) {
        event.target.value = event.target.value.replace(/\D/g, '')
        if (event.target.value > 60) event.target.value = 60
    }

    changeData() {
        this.data.minute = this.data.minute.replace(/\D/g, '')
        this.data.second = this.data.second.replace(/\D/g, '')
        const newDateParse = `${this.data.hour}:${this.data.minute}:${this.data.second}`
        this.setModelValue(newDateParse)

    }

}
