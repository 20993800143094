import {Component, ElementRef, OnInit} from '@angular/core'
import {CreatorExtendCore} from '../../extend/card-extend-core'
import {UserService} from '../../../../services/user/user-service'
import {TextAreaModel} from './textarea-model'

@Component({
    selector: 'vold-text-area',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})

export class TextareaComponent extends CreatorExtendCore implements OnInit {

    textAreaOption: TextAreaModel
    reachLimitNote: string

    constructor(public ref: ElementRef,
                public userService: UserService) {
        super(userService, ref)
    }

    extendOnInit() {
        this.textAreaOption = this.formDetail.textArea
    }

    onKeyupTyping(event) {

        if (!this.textAreaOption) return

        if (event.target.value.length > this.textAreaOption.limit) {
            event.target.value = event.target.value.slice(0, this.textAreaOption.limit)
            this.reachLimitNote = 'Your content is out of limit!'
        } else {
            this.reachLimitNote = `${this.textAreaOption.limit - event.target.value.length} char left`
        }

    }

}
