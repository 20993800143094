import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {CreateNewPostService} from '../../services/create/create-new-post.service'
import {UserService} from '../../services/user/user-service'
import {DomActionService} from '../../services/dom/dom-action.service'
import {ActivatedRoute} from '@angular/router'
import {TimeoutWait} from '../../helpers/helper-async-timeout'
import {EditorService} from '../../services/editor/editor-service'

@Component({
    selector: 'vold-create-post-type',
    templateUrl: './create-post-type.component.html',
    styleUrls: ['./create-post-type.component.scss']
})
export class CreatePostTypeComponent implements AfterViewInit, OnInit {

    postTitle: string = 'Page'
    model: string = 'page'
    isShow: boolean = false
    formCreateNewPost: any = {}
    isBusy: boolean
    isSetAsFrontPage: boolean
    currentItemActive: number = 0
    @ViewChild('outerItemContainer') outerItemContainer: ElementRef
    @Output() onClosed: EventEmitter<any> = new EventEmitter<any>()

    constructor(private _el: ElementRef,
                private _post: CreateNewPostService,
                private _domService: DomActionService,
                private _activatedRoute: ActivatedRoute,
                public editorService: EditorService,
                public userService: UserService) {
    }

    ngOnInit() {

        this._activatedRoute.queryParams.subscribe(params => {
            if (params.type) this.postTitle = 'Post'
        })
    }

    ngAfterViewInit() {
        this._domService.$listenEscHint(res => {
            if (res) this.show(false)
        })
    }

    async show(show: boolean = true) {
        this._el.nativeElement.classList[show ? 'add' : 'remove']('active')

        if (!this.editorService.data.singleData['meta']) this.editorService.data.singleData.meta = {
            title: {},
            description: {},
            ogImage: null,
        }

        if (!this.editorService.data.singleData.summary) this.editorService.data.singleData['summary'] = {}
        if (!this.editorService.data.singleData.featured) this.editorService.data.singleData['featured'] = null

        this.formCreateNewPost = this.editorService.data.singleData

        /**
         * Check if page is default page
         */
        if (this.userService.websiteSettings.default_homepage && this.userService.websiteSettings.default_homepage.id === this.formCreateNewPost.id)
            this.isSetAsFrontPage = true

        await TimeoutWait(200)
        this.isShow = show

        if (!show) this.onClosed.emit(show)
    }

    setDetail(model: string) {
        this.model = model
    }

    async createPost() {
        this.isBusy = true
        await this.editorService.data.update(this.editorService.pageType, this.formCreateNewPost)
        this.isBusy = false

    }

    async setAsFrontPage() {
        this.isBusy = true
        this.userService.websiteSettings.default_homepage = this.editorService.data.singleData.id
        const updatedWebSetting = await this.editorService.data.update('webconfig', this.userService.websiteSettings)
        this.isBusy = false
        this.isSetAsFrontPage = true
        this.userService.websiteSettings = updatedWebSetting
    }

    publishPage() {

        if (this.formCreateNewPost.status === 'published') this.formCreateNewPost.status = 'draft'
        else this.formCreateNewPost.status = 'published'

    }

    showSetting(index: number) {
        this.outerItemContainer.nativeElement.style.transform = `translate3d(-${index * 100}%,0,0)`
        this.outerItemContainer.nativeElement.style.webkitTransform = `translate3d(-${index * 100}%,0,0)`
        this.currentItemActive = index
    }

}
